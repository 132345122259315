import { Group } from 'three';
import { Materials } from '../../shared/Materials';

/**
 * @param {Group} burnerParts
 * @param {Materials} materials
 */
export function buildBurners(burnerParts, materials) {
  const fiveKParts = gather5KParts(burnerParts);
  const elevenKParts = gather11KParts(burnerParts);
  const fifteenKParts = gather15KParts(burnerParts);
  const eighteenKParts = gather18KParts(burnerParts);
  const thermocoupleParts = gatherThermocoupleParts(burnerParts);
  const ignitorParts = gatherIgnitorParts(burnerParts);

  materials.applyBurnerMaterials([
    fiveKParts,
    elevenKParts,
    fifteenKParts,
    eighteenKParts,
  ]);

  materials.applyThermocoupleMaterials(thermocoupleParts);
  materials.applyIgnitorMaterials(ignitorParts);

  const thermocouple = thermocoupleGroup(thermocoupleParts);
  const ignitor = ignitorGroup(ignitorParts);

  return [
    fiveKBurner(fiveKParts, thermocouple, ignitor),
    elevenKBurner(elevenKParts, thermocouple, ignitor),
    fifteenKBurner(fifteenKParts, thermocouple, ignitor),
    eighteenKBurner(eighteenKParts, thermocouple, ignitor),
  ];
}

function fiveKBurner(fiveKParts, thermocoupleGroup, ignitorGroup) {
  const fiveKBurner = new Group();

  const thermocouple = thermocoupleGroup.clone();
  thermocouple.translateX(0.0011);
  thermocouple.translateZ(0.0262);

  const ignitor = ignitorGroup.clone();
  ignitor.translateX(-0.0011);
  ignitor.translateZ(0.026);

  const burnerParts = [
    fiveKParts.cap,
    fiveKParts.bowl,
    fiveKParts.blackRing,
    thermocouple,
    ignitor,
  ];

  burnerParts.forEach((mesh) => fiveKBurner.add(mesh));

  fiveKBurner.translateZ(0.1435);

  return fiveKBurner;
}

function elevenKBurner(elevenKParts, thermocoupleGroup, ignitorGroup) {
  const elevenKBurner = new Group();

  const thermocouple = thermocoupleGroup.clone();
  thermocouple.translateX(0.0011);
  thermocouple.translateZ(0.0177);

  const ignitor = ignitorGroup.clone();
  ignitor.translateX(-0.0011);
  ignitor.translateZ(0.018);

  const burnerParts = [
    elevenKParts.cap,
    elevenKParts.bowl,
    elevenKParts.blackRing,
    thermocouple,
    ignitor,
  ];

  burnerParts.forEach((mesh) => elevenKBurner.add(mesh));

  elevenKBurner.translateZ(0.1435);

  return elevenKBurner;
}

function fifteenKBurner(fifteenKParts, thermocoupleGroup, ignitorGroup) {
  const fifteenKBurner = new Group();

  const thermocouple = thermocoupleGroup.clone();
  thermocouple.translateZ(0.0085);

  const ignitor = ignitorGroup.clone();
  ignitor.translateZ(0.0085);

  const burnerParts = [
    fifteenKParts.cap,
    fifteenKParts.bowl,
    fifteenKParts.blackRing,
    thermocouple,
    ignitor,
  ];

  burnerParts.forEach((mesh) => fifteenKBurner.add(mesh));

  fifteenKBurner.translateZ(0.1435);

  return fifteenKBurner;
}

function eighteenKBurner(eighteenKParts, thermocoupleGroup, ignitorGroup) {
  const eighteenKBurner = new Group();

  const burnerParts = [
    eighteenKParts.cap,
    eighteenKParts.bowl,
    eighteenKParts.blackRing,
    thermocoupleGroup.clone(),
    ignitorGroup.clone(),
  ];

  burnerParts.forEach((mesh) => eighteenKBurner.add(mesh));

  return eighteenKBurner;
}

/** @param {Group} burnerParts */
function gather5KParts(burnerParts) {
  const cap = burnerParts.getObjectByName('5K_btu_Top_15kW');
  cap.castShadow = true;

  const bowl = burnerParts.getObjectByName('5K_btu_Bottom_15kW');
  bowl.castShadow = true;
  bowl.receiveShadow = true;

  return {
    cap: cap,
    bowl: bowl,
    blackRing: burnerParts.getObjectByName('5K_btu_Top_BlackBody_15kW'),
  };
}

/** @param {Group} burnerParts */
function gather11KParts(burnerParts) {
  const cap = burnerParts.getObjectByName('11K_btu_Top_3kW');
  cap.castShadow = true;

  const bowl = burnerParts.getObjectByName('11K_btu_Bottom_3kW');
  bowl.castShadow = true;
  bowl.receiveShadow = true;

  return {
    cap: cap,
    bowl: bowl,
    blackRing: burnerParts.getObjectByName('11K_btu_Top_BlackBody_3kW'),
  };
}

/** @param {Group} burnerParts */
function gather15KParts(burnerParts) {
  const cap = burnerParts.getObjectByName('15K_btu_Top_4kW');
  cap.castShadow = true;

  const bowl = burnerParts.getObjectByName('15K_btu_Bottom_4kW');
  bowl.castShadow = true;
  bowl.receiveShadow = true;

  return {
    cap: cap,
    bowl: bowl,
    blackRing: burnerParts.getObjectByName('15K_btu_Top_BlackBody_4kW'),
  };
}

/** @param {Group} burnerParts */
function gather18KParts(burnerParts) {
  const cap = burnerParts.getObjectByName('18K_btu_Top_5kW');
  cap.castShadow = true;

  const bowl = burnerParts.getObjectByName('18K_btu_Bottom_5kW');
  bowl.castShadow = true;
  bowl.receiveShadow = true;

  return {
    cap: cap,
    bowl: bowl,
    blackRing: burnerParts.getObjectByName('18K_btu_Top_BlackBody_5kW'),
  };
}

/** @param {Group} burnerParts */
function gatherThermocoupleParts(burnerParts) {
  return {
    inner: burnerParts.getObjectByName('Burner_Thermocouple_InnerBody'),
    outer: burnerParts.getObjectByName('Burner_Thermocouple_OuterBody'),
  };
}

/** @param {Group} burnerParts */
function gatherIgnitorParts(burnerParts) {
  return {
    inner: burnerParts.getObjectByName('Burner_Igniter_InnerBody'),
    outer: burnerParts.getObjectByName('Burner_Igniter_OuterBody'),
  };
}

function thermocoupleGroup(thermocoupleParts) {
  const thermocouple = new Group();

  thermocouple.add(
    thermocoupleParts.outer.clone(),
    thermocoupleParts.inner.clone()
  );

  return thermocouple;
}

function ignitorGroup(ignitorParts) {
  const ignitor = new Group();

  ignitor.add(ignitorParts.outer.clone(), ignitorParts.inner.clone());

  return ignitor;
}
