import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Cupboard, Oven, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, ?string>>}
   */
  selectedKnobs = {
    left: {
      baseLeftBurnerFront: Burner.gasBurner,
      baseLeftBurnerBack: Burner.gasBurner,
      baseCenterBurner: Burner.gasBurner,
      baseRightBurnerBack: null,
      baseRightBurnerFront: null,
      oven: Oven.convection,
    },
    farRight: {
      optionBack: null,
      optionFront: null,
      cupboard: Cupboard.warming,
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);
  }

  threeFeuxBaseTop() {
    this.selectedKnobs.left.baseLeftBurnerFront = null;
    this.selectedKnobs.left.baseLeftBurnerBack = null;
    this.selectedKnobs.left.baseCenterBurner = Burner.gasBurner;
    this.selectedKnobs.left.baseRightBurnerBack = Burner.gasBurner;
    this.selectedKnobs.left.baseRightBurnerFront = Burner.gasBurner;

    this.updateLeftKnobs();
  }

  fourFeuxBaseTop() {
    this.selectedKnobs.left.baseLeftBurnerFront = Burner.gasBurner;
    this.selectedKnobs.left.baseLeftBurnerBack = Burner.gasBurner;
    this.selectedKnobs.left.baseCenterBurner = null;
    this.selectedKnobs.left.baseRightBurnerBack = Burner.gasBurner;
    this.selectedKnobs.left.baseRightBurnerFront = Burner.gasBurner;

    this.updateLeftKnobs();
  }

  /**
   * Change the cupboard to have a knob for a warming cupboard
   * @param {string} region
   */
  selectWarmingCupboard(region) {
    switch (region) {
      case Region.farRight:
        this.selectedKnobs.farRight.cupboard = Cupboard.warming;
        this.updateFarRightKnobs();
        break;
    }
  }

  /**
   * Remove the warming cupboard knob
   * @param {string} region
   */
  selectStorageCupboard(region) {
    switch (region) {
      case Region.farRight:
        this.selectedKnobs.farRight.cupboard = null;
        this.updateFarRightKnobs();
        break;
    }
  }

  /** Update the knobs on the range's left */
  updateLeftKnobs() {
    this.updateKnobRegion(Region.left, {
      maxKnobs: 5,
      offset: 0.54,
      gap: 0.11,
    });
  }

  /** Update the knobs on the range's right */
  updateFarRightKnobs() {
    this.updateKnobRegion(Region.farRight, {
      maxKnobs: 3,
      offset: 1.2,
      gap: 0.1,
    });
  }
}
