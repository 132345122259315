import { BufferGeometry, Group, Mesh, Object3D } from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';

import { BaseSSRangeTop } from '../shared/BaseSSRangeTop';
import { RangeTopOptions } from '../shared/RangeTopOptions';

import { Burner, MeshName } from '../../shared/Enums';

export class SSRangeTop extends BaseSSRangeTop {
  /**
   * An outer rim geometry for a Sully range top
   * @type {BufferGeometry}
   */
  #topRimGeo;

  /** @type {Mesh} */
  #topRimMesh;

  /**
   * @typedef TopState
   * @type {object}
   * @property {boolean} [fourFeux] - is the range top 4-feux
   * @property {string} [option1] - the first (left) range top option
   * @property {string} [option2] - the second (right) range top option
   */

  /**
   * Configured range top options
   * @type {TopState}
   */
  #state = {};

  /**
   * @param {Group} range
   * @param {RangeTopOptions} rangeTopOptions
   */
  constructor(range, rangeTopOptions) {
    super(rangeTopOptions, 0.4);

    this.#makeTopRimGeometry(range);
  }

  /**
   * The fully configured stainless steel range top - based on range top options
   * @param {TopState} newState
   * @returns {Mesh}
   */
  assembleSSRangeTop(newState) {
    this.#state = { ...this.#state, ...newState };

    const geometries = [
      this.#topRimGeo,
      this.#option1Surface(),
      this.#spacerOption1toBase(),
      this.#leftBaseSurface(),
      this.#spacerBaseToOption2(),
      this.#option2Surface(),
    ];
    if (!this.#state.fourFeux) {
      geometries.push(this.#midBaseSpacer(), this.#rightBaseSurface());
    }

    const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries);

    const rangeTop = new Mesh(mergedGeometry, this.#topRimMesh.material);
    rangeTop.name = 'range-top';
    rangeTop.receiveShadow = true;

    return rangeTop;
  }

  /**
   * Create a geometry for the outer rim of the range top
   * @param {Group} range
   */
  #makeTopRimGeometry(range) {
    // @ts-ignore
    this.#topRimMesh = range.getObjectByName(MeshName.rangeTopRim1400);

    this.#topRimGeo = this.#topRimMesh.geometry.clone();
    this.#topRimGeo.applyQuaternion(this.#topRimMesh.quaternion);
    this.#topRimGeo.translate(
      this.#topRimMesh.position.x,
      this.#topRimMesh.position.y,
      this.#topRimMesh.position.z
    );
  }

  /**
   * A range top surface geometry for the first range top option
   * @returns {BufferGeometry}
   */
  #option1Surface() {
    let offset = 0;

    if (this.#state.fourFeux) {
      // More space to the left of the 4 feux burners
      switch (this.#state.option1) {
        case Burner.two11kBurners:
        case Burner.two15kBurners:
          return this.wide2BurnerPond(offset);

        case Burner.one18kBurner:
          return this.oneBurnerPond(offset);

        default:
          return this.wideWorkstation(offset);
      }
    } else {
      // Narrow space for optional burners
      switch (this.#state.option1) {
        case Burner.two11kBurners:
        case Burner.two15kBurners:
          return this.narrow2BurnerPond(offset);

        default:
          return this.narrowWorkstation(offset);
      }
    }
  }

  /**
   * A range top spacer geometry between the first range top option and the base burners
   * @returns {BufferGeometry}
   */
  #spacerOption1toBase() {
    if (this.#state.fourFeux) {
      return this.spacer16mm(0.39);
    } else {
      return this.spacer28mm(0.26);
    }
  }

  /**
   * A range top surface geometry for the left "half" of the base burners
   * @returns {BufferGeometry}
   */
  #leftBaseSurface() {
    if (this.#state.fourFeux) {
      return this.sully4BurnerPond(0.406);
    } else {
      return this.oneBurnerPond(0.288);
    }
  }

  /**
   * A range top spacer geometry between the two halves of the base burners
   * @returns {BufferGeometry}
   */
  #midBaseSpacer() {
    return this.spacer28mm(0.678);
  }

  /**
   * A range top surface geometry for the right "half" of the base burners
   * @returns {BufferGeometry}
   */
  #rightBaseSurface() {
    return this.narrow2BurnerPond(0.706);
  }

  /**
   * A range top spacer geometry between the first range top option and the base burners
   * @returns {BufferGeometry}
   */
  #spacerBaseToOption2() {
    if (this.#state.fourFeux) {
      return this.spacer16mm(0.978);
    } else {
      return this.spacer28mm(0.966);
    }
  }

  /**
   * A range top surface geometry for the fourth range top option
   * @returns {BufferGeometry}
   */
  #option2Surface() {
    const offset = 0.994;

    switch (this.#state.option2) {
      case Burner.two11kBurners:
      case Burner.two15kBurners:
        return this.wide2BurnerPond(offset);

      case Burner.one18kBurner:
      case Burner.traditionalSimmerPlate:
      case Burner.multiCooker:
        return this.oneBurnerPond(offset);

      case Burner.flameGrill:
        return this.flameGrillPond(offset);

      default:
        return this.wideWorkstation(offset);
    }
  }
}
