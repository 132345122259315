import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Cupboard, Oven, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, ?string>>}
   */
  selectedKnobs = {
    center: {
      petiteOven: Oven.petite,
      leftOven: Oven.electric,
      baseLeftBurnerFront: Burner.gasBurner,
      baseLeftBurnerBack: Burner.gasBurner,
      baseBurnerCenter: Burner.gasBurner,
      baseRightBurnerFront: Burner.gasBurner,
      baseRightBurnerBack: Burner.gasBurner,
      rightOven: Oven.gas,
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);

    this.updateCenterKnobs();
  }

  /** Update all of the Chagny's knobs */
  updateCenterKnobs() {
    this.updateKnobRegion(Region.center, {
      maxKnobs: 8,
      offset: 0.565,
      gap: 0.115,
    });
  }
}
