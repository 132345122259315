import { RepeatWrapping, SRGBColorSpace, TextureLoader, Texture } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

// @ts-ignore
import backImage from '/assets/textures/body_back_texture_final.webp?url';
// @ts-ignore
import blackTextureImage from '/assets/textures/black-plain-concrete-textured.jpg?url';
// @ts-ignore
import brassCapImage from '/assets/textures/texture_1.png?url';
// @ts-ignore
import cherryRedImage from '/assets/textures/cherry-red-lacanche-colour-sample.jpg?url';
// @ts-ignore
import nickelTextureImage from '/assets/textures/nickel-lacanche-sample.jpg?url';
// @ts-ignore
import ssTextureImage from '/assets/textures/stainless-steel-lacanche-sample.jpg?url';
// @ts-ignore
import lacancheLogoTexture from '/assets/textures/lacanche-logo-plate.jpg?url';
// @ts-ignore
import lacancheLogoAOTexture from '/assets/textures/lacanche-logo-plate-ao.jpg?url';

export class AssetLoader {
  // GLB 3D models for range
  /** @typedef {import("three/examples/jsm/loaders/GLTFLoader.js").GLTF} GLTF */

  /** @type {GLTF} */
  beauneData;

  /** @type {GLTF} */
  bussyData;

  /** @type {GLTF} */
  chagnyData;

  /** @type {GLTF} */
  chagny1400LeftData;

  /** @type {GLTF} */
  chagny1400RightData;

  /** @type {GLTF} */
  chagny1800Data;

  /** @type {GLTF} */
  chambertinData;

  /** @type {GLTF} */
  chassagneData;

  /** @type {GLTF} */
  clunyData;

  /** @type {GLTF} */
  cluny1400LeftData;

  /** @type {GLTF} */
  cluny1400RightData;

  /** @type {GLTF} */
  cluny1800Data;

  /** @type {GLTF} */
  cormatinData;

  /** @type {GLTF} */
  fontenayData;

  /** @type {GLTF} */
  rullyData;

  /** @type {GLTF} */
  sullyData;

  /** @type {GLTF} */
  sully1800LeftData;

  /** @type {GLTF} */
  sully1800RightData;

  /** @type {GLTF} */
  sully2200Data;

  /** @type {GLTF} */
  volnayData;

  /** @type {GLTF} */
  vougeotData;

  /** @type {GLTF} */
  allBurnersData;

  /** @type {GLTF} */
  controlPanelPartsData;

  /** @type {GLTF} */
  fourFeuxSpacerData;

  /** @type {GLTF} */
  eighteenKBurnerGrateData;

  /** @type {GLTF} */
  flameGrillData;

  /** @type {GLTF} */
  inductionRingsData;

  /** @type {GLTF} */
  logoData;

  /** @type {GLTF} */
  multiCookerData;

  /** @type {GLTF} */
  planchaData;

  /** @type {GLTF} */
  rangePartsData;

  /** @type {GLTF} */
  rangetopPiecesData;

  /** @type {GLTF} */
  traditionalPlateData;

  /** @type {GLTF} */
  twoBurnerGrateData;

  /** @type {GLTF} */
  backsplash2200Data;

  /** @type {GLTF} */
  hood2400Data;

  // Texture images

  /** @type {Texture} */
  backTexture;

  /** @type {Texture} */
  blackTexture;

  /** @type {Texture} */
  brassCapTexture;

  /** @type {Texture} */
  cherryRedTexture;

  /** @type {Texture} */
  nickelTexture;

  /** @type {Texture} */
  ssTexture;

  /** @type {Texture} */
  lacancheLogoTexture;

  /** @type {Texture} */
  lacancheLogoAOTexture;

  // Three.js loaders
  #gltfLoader;
  #textureLoader;

  constructor() {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco/');

    this.#gltfLoader = new GLTFLoader();
    this.#gltfLoader.setDRACOLoader(dracoLoader);

    this.#textureLoader = new TextureLoader();
  }

  async loadMinimum() {
    [
      // Base range 3D models
      this.chagny1400RightData,
      this.sullyData,

      // Range components 3D models
      this.twoBurnerGrateData,
      this.fourFeuxSpacerData,
      this.eighteenKBurnerGrateData,
      this.allBurnersData,
      this.controlPanelPartsData,
      this.planchaData,
      this.flameGrillData,
      this.inductionRingsData,
      this.logoData,
      this.multiCookerData,
      this.rangePartsData,
      this.rangetopPiecesData,
      this.traditionalPlateData,
      // Texture images
      this.backTexture,
      this.blackTexture,
      this.brassCapTexture,
      this.cherryRedTexture,
      this.nickelTexture,
      this.ssTexture,
      this.lacancheLogoTexture,
      this.lacancheLogoAOTexture,
    ] = await Promise.all([
      // 3D range files
      this.#gltfLoader.loadAsync('/models/Chagny_1400_right.glb'),
      this.#gltfLoader.loadAsync('/models/Sully.glb'),
      // 3D range component files
      this.#gltfLoader.loadAsync('/models/2_burner_grate.glb'),
      this.#gltfLoader.loadAsync('/models/4_feux_spacer.glb'),
      this.#gltfLoader.loadAsync('/models/18k_burner_grate.glb'),
      this.#gltfLoader.loadAsync('/models/Burners.glb'),
      this.#gltfLoader.loadAsync('/models/Control_panel_parts.glb'),
      this.#gltfLoader.loadAsync('/models/Electric_plancha.glb'),
      this.#gltfLoader.loadAsync('/models/Gas_grill.glb'),
      this.#gltfLoader.loadAsync('/models/Induction_rings.glb'),
      this.#gltfLoader.loadAsync('/models/Logo.glb'),
      this.#gltfLoader.loadAsync('/models/Multi_cooker.glb'),
      this.#gltfLoader.loadAsync('/models/Range_parts.glb'),
      this.#gltfLoader.loadAsync('/models/Rangetop_pieces.glb'),
      this.#gltfLoader.loadAsync('/models/Simmer_plate.glb'),
      // Image files
      this.#textureLoader.loadAsync(backImage),
      this.#textureLoader.loadAsync(blackTextureImage),
      this.#textureLoader.loadAsync(brassCapImage),
      this.#textureLoader.loadAsync(cherryRedImage),
      this.#textureLoader.loadAsync(nickelTextureImage),
      this.#textureLoader.loadAsync(ssTextureImage),
      this.#textureLoader.loadAsync(lacancheLogoTexture),
      this.#textureLoader.loadAsync(lacancheLogoAOTexture),
    ]);

    this.lacancheLogoTexture.colorSpace = SRGBColorSpace;

    this.#setTextureWrapping();
  }

  async loadRemaining() {
    [
      // Base range models
      this.beauneData,
      this.bussyData,
      this.chagnyData,
      this.chagny1400LeftData,
      this.chagny1800Data,
      this.chambertinData,
      this.chassagneData,
      this.clunyData,
      this.cluny1400LeftData,
      this.cluny1400RightData,
      this.cluny1800Data,
      this.cormatinData,
      this.fontenayData,
      this.rullyData,
      this.sully1800LeftData,
      this.sully1800RightData,
      this.sully2200Data,
      this.volnayData,
      this.vougeotData,

      // Base backsplash models
      this.backsplash2200Data,

      // Base hood models
      this.hood2400Data,
    ] = await Promise.all([
      // 3D range files
      this.#gltfLoader.loadAsync('/models/Beaune.glb'),
      this.#gltfLoader.loadAsync('/models/Bussy.glb'),
      this.#gltfLoader.loadAsync('/models/Chagny.glb'),
      this.#gltfLoader.loadAsync('/models/Chagny_1400_left.glb'),
      this.#gltfLoader.loadAsync('/models/Chagny_1800.glb'),
      this.#gltfLoader.loadAsync('/models/Chambertin.glb'),
      this.#gltfLoader.loadAsync('/models/Chassagne.glb'),
      this.#gltfLoader.loadAsync('/models/Cluny.glb'),
      this.#gltfLoader.loadAsync('/models/Cluny_1400_left.glb'),
      this.#gltfLoader.loadAsync('/models/Cluny_1400_right.glb'),
      this.#gltfLoader.loadAsync('/models/Cluny_1800.glb'),
      this.#gltfLoader.loadAsync('/models/Cormatin.glb'),
      this.#gltfLoader.loadAsync('/models/Fontenay.glb'),
      this.#gltfLoader.loadAsync('/models/Rully.glb'),
      this.#gltfLoader.loadAsync('/models/Sully_1800_left.glb'),
      this.#gltfLoader.loadAsync('/models/Sully_1800_right.glb'),
      this.#gltfLoader.loadAsync('/models/Sully_2200.glb'),
      this.#gltfLoader.loadAsync('/models/Volnay.glb'),
      this.#gltfLoader.loadAsync('/models/Vougeot.glb'),
      // 3D backsplash files
      this.#gltfLoader.loadAsync('/models/BackSplash_2200.glb'),
      // 3D hood files
      this.#gltfLoader.loadAsync('/models/Hood_2400.glb'),
    ]);
  }

  #setTextureWrapping() {
    this.backTexture.repeat.set(1, 1);
    this.backTexture.wrapS = RepeatWrapping;
    this.backTexture.wrapT = RepeatWrapping;

    this.blackTexture.repeat.set(2, 2);
    this.blackTexture.wrapS = RepeatWrapping;
    this.blackTexture.wrapT = RepeatWrapping;

    this.brassCapTexture.repeat.set(1, 1);
    this.brassCapTexture.wrapS = RepeatWrapping;
    this.brassCapTexture.wrapT = RepeatWrapping;

    this.cherryRedTexture.repeat.set(1, 1);
    this.cherryRedTexture.wrapS = RepeatWrapping;
    this.cherryRedTexture.wrapT = RepeatWrapping;

    this.ssTexture.repeat.set(2, 2);
    this.ssTexture.wrapS = RepeatWrapping;
    this.ssTexture.wrapT = RepeatWrapping;
  }
}
