import { Color, MeshStandardMaterial } from 'three';
import GUI from 'lil-gui';
import saveAs from 'file-saver';
import { MeshName } from '../shared/Enums.js';

export class HelperGUI {
  cameraX = null;
  gui;
  material = new MeshStandardMaterial();

  #debugObject = {
    cameraType: 'Orthographic',
    showEnvMapInBackground: false,
    screenshot: this.#saveImage,
  };

  constructor() {
    this.gui = new GUI();
    this.gui.close();
  }

  addMaterial(range) {
    const materialFolder = this.gui.addFolder('Colors');
    materialFolder.close();
    this.changeMaterial(range);
    range.changeMaterial(this.material);

    this.#debugObject.color = this.material.color.getHexString();
    this.#debugObject.roughness = this.material.roughness;
    this.#debugObject.metalness = this.material.metalness;

    this.guiColor = materialFolder
      .addColor(this.#debugObject, 'color')
      .onChange(() => {
        this.material.color.set(this.#debugObject.color);
        range.changeMaterial(this.material);
      })
      .name('range color');

    this.guiMetalness = materialFolder
      .add(this.#debugObject, 'metalness')
      .min(0)
      .max(1)
      .step(0.01)
      .onChange(() => {
        this.material.metalness = this.#debugObject.metalness;
        range.changeMaterial(this.material);
      })
      .name('metalness');

    this.guiRoughness = materialFolder
      .add(this.#debugObject, 'roughness')
      .min(0)
      .max(1)
      .step(0.01)
      .onChange(() => {
        this.material.roughness = this.#debugObject.roughness;
        range.changeMaterial(this.material);
      })
      .name('roughness');
  }

  changeMaterial(range) {
    const existingMaterial = range.range.getObjectByName(
      MeshName.controlPanel
    ).material;

    this.material = new MeshStandardMaterial({
      color: existingMaterial.color,
      metalness: existingMaterial.metalness,
      roughness: existingMaterial.roughness,
    });

    this.#debugObject.color = '#' + this.material.color.getHexString();
    this.#debugObject.roughness = this.material.roughness;
    this.#debugObject.metalness = this.material.metalness;

    if (this.guiColor) {
      this.guiColor.updateDisplay();
      this.guiMetalness.updateDisplay();
      this.guiRoughness.updateDisplay();
    }
  }

  addCamera(camera) {
    const cameraFolder = this.gui.addFolder('Camera');
    cameraFolder.close();

    this.cameraX = cameraFolder
      .add(camera.position, 'x', -5, 5, 0.01)
      .name('positionX');
    this.cameraY = cameraFolder
      .add(camera.position, 'y', -5, 5, 0.01)
      .name('positionY');
    this.cameraZ = cameraFolder
      .add(camera.position, 'z', -5, 5, 0.01)
      .name('positionZ');
  }

  addEnvironmentMap(scene) {
    const environmentMapFolder = this.gui.addFolder('Environment Map');
    environmentMapFolder.close();

    environmentMapFolder
      .add(scene, 'environmentIntensity', 0, 5, 0.1)
      .name('intensity');
    environmentMapFolder
      .add(this.#debugObject, 'showEnvMapInBackground')
      .onChange(() => {
        if (this.#debugObject.showEnvMapInBackground) {
          scene.background = scene.environment;
        } else {
          scene.background = new Color('rgb(255, 250, 241)');
        }
      })
      .name('show');
  }

  addHemiLight(hemiLight) {
    const hemiLightFolder = this.gui.addFolder('Hemisphere Light');
    hemiLightFolder.close();

    hemiLightFolder.add(hemiLight, 'intensity', 0, 5, 0.1);
  }

  addLeftLight(leftLight) {
    const leftLightFolder = this.gui.addFolder('Left Light');
    leftLightFolder.close();

    leftLightFolder.add(leftLight, 'intensity', 0, 5, 0.1);
    leftLightFolder.add(leftLight.position, 'x', -5, 0, 0.05).name('positionX');
    leftLightFolder.add(leftLight.position, 'y', 1, 5, 0.05).name('positionY');
    leftLightFolder.add(leftLight.position, 'z', -2, 8, 0.05).name('positionZ');
    // leftLightFolder
    //   .add(leftLight.target.position, 'x', -3, 3, 0.1)
    //   .name('targetX');
    // leftLightFolder
    //   .add(leftLight.target.position, 'y', -2, 2, 0.1)
    //   .name('targetY');
    // leftLightFolder
    //   .add(leftLight.target.position, 'z', -6, 4, 0.1)
    //   .name('targetZ');
  }

  addRightLight(rightLight) {
    const rightLightFolder = this.gui.addFolder('Right Light');
    rightLightFolder.close();

    rightLightFolder.add(rightLight, 'intensity', 0, 5, 0.1);
    rightLightFolder
      .add(rightLight.position, 'x', 0, 5, 0.05)
      .name('positionX');
    rightLightFolder
      .add(rightLight.position, 'y', 1, 5, 0.05)
      .name('positionY');
    rightLightFolder
      .add(rightLight.position, 'z', -2, 8, 0.05)
      .name('positionZ');
    // rightLightFolder
    //   .add(rightLight.target.position, 'x', -3, 3, 0.1)
    //   .name('targetX');
    // rightLightFolder
    //   .add(rightLight.target.position, 'y', -2, 2, 0.1)
    //   .name('targetY');
    // rightLightFolder
    //   .add(rightLight.target.position, 'z', -6, 4, 0.1)
    //   .name('targetZ');
  }

  screenshot() {
    this.gui.add(this.#debugObject, 'screenshot').name('take screenshot');
  }

  tick() {
    if (this.cameraX) {
      this.cameraX.updateDisplay();
      this.cameraY.updateDisplay();
      this.cameraZ.updateDisplay();
    }
  }

  #saveImage() {
    const fileName = 'screenshot.png';
    var image = document
      .getElementsByTagName('canvas')[0]
      .toDataURL()
      .replace('image/png', 'image/octet-stream');

    saveAs(image, `${fileName.split('.')[0]}.png`);
  }
}
