import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Cupboard, Burner, Oven, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, ?string>>}
   */
  selectedKnobs = {
    center: {
      oven: Oven.convectionAlt,
      baseLeftBurnerFront: Burner.gasBurner,
      baseLeftBurnerBack: Burner.gasBurner,
      baseBurnerCenter: Burner.gasBurner,
      baseRightBurnerFront: Burner.gasBurner,
      baseRightBurnerBack: Burner.gasBurner,
      cupboard: Cupboard.warming,
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);
  }

  /** Update all of the Volnay's knobs */
  updateCenterKnobs() {
    this.updateKnobRegion(Region.center, {
      maxKnobs: 7,
      offset: 0.63,
      gap: 0.11,
    });
  }
}
