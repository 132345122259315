import { Group } from 'three';
import { AssetLoader } from '../shared/AssetLoader';
import { Materials } from '../shared/Materials';

export class Backsplash2200 {
  backsplash = new Group();

  /** @type {AssetLoader} */
  #assets;

  /** @type {Materials} */
  #materials;
  #supportBar;
  #zHook;

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   */
  constructor(assets, materials) {
    this.backsplash.name = 'backsplash-2200';
    this.backsplash.position.z = -0.015;
    this.#assets = assets;
    this.#materials = materials;
    this.loadModels();
    this.applyTextures();
    this.adjustZHookPosition();
    this.addPotRailSupports();
    this.addZHook();
  }

  loadModels() {
    while (this.#assets.backsplash2200Data.scene.children.length) {
      this.backsplash.add(this.#assets.backsplash2200Data.scene.children[0]);
    }
  }

  applyTextures() {
    const backsplash = this.backsplash.getObjectByName('BackSplash_2200');
    const potRailBar = this.backsplash.getObjectByName(
      'BackSplash_pot_rail_bar_2200'
    );
    this.#zHook = this.backsplash.getObjectByName('BackSplash_Z_Hook');
    this.#supportBar = this.backsplash.getObjectByName(
      'BackSplash_support_bar_2200'
    );

    this.#materials.applyStainlessSteelMaterial(backsplash, this.#supportBar);
    this.#materials.applyGalvanizedSteelMaterial(potRailBar, this.#zHook);
  }

  adjustZHookPosition() {
    this.#zHook.position.z += 0.015;
  }

  addPotRailSupports() {
    const leftSupportBar = this.#supportBar.clone();
    leftSupportBar.translateX(-1.035);
    this.backsplash.add(leftSupportBar);

    const rightSupportBar = this.#supportBar.clone();
    rightSupportBar.translateX(1.035);
    this.backsplash.add(rightSupportBar);
  }

  addZHook() {
    const leftZHook = this.#zHook.clone();
    leftZHook.position.x += -2.06;
    this.backsplash.add(leftZHook);
  }
}
