const setSize = (container, camera, renderer) => {
  const aspect = container.clientWidth / container.clientHeight;
  camera.aspect = aspect;
  // TODO: Remove these 4 lines for the OrthographicCamera
  camera.top = 1;
  camera.right = aspect;
  camera.bottom = -1;
  camera.left = -aspect;
  camera.updateProjectionMatrix();

  renderer.setSize(container.clientWidth, container.clientHeight);
  renderer.setPixelRatio(window.devicePixelRatio);
};

export class Resizer {
  constructor(container, camera, renderer) {
    setSize(container, camera, renderer);

    window.addEventListener('resize', () => {
      setSize(container, camera, renderer);
      this.onResize();
    });
  }

  onResize() {}
}
