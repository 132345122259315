import { Group, Object3D } from 'three';
import * as common from './common.js';
import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Chagny 1800 range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Cupboard Doors
  const leftCupboardDoor = meshes
    .getObjectByName(MeshName.cupboardDoor)
    .clone();
  leftCupboardDoor.name = MeshName.leftCupboardDoor;
  leftCupboardDoor.position.x = -0.702;
  const rightCupboardDoor = leftCupboardDoor.clone();
  rightCupboardDoor.name = MeshName.rightCupboardDoor;
  rightCupboardDoor.position.x = 0.702;

  // Cupboard Vent Covers
  const leftCupboardVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCover)
    .clone();
  leftCupboardVentCover.position.x = -0.7;
  const rightCupboardVentCover = leftCupboardVentCover.clone();
  rightCupboardVentCover.position.x = 0.7;

  return [
    leftCupboardDoor,
    rightCupboardDoor,
    leftCupboardVentCover,
    rightCupboardVentCover,
    ...common.chagnyOvenDoorsAndDrawers(meshes),
  ];
}

/**
 * All the classique trim parts for a Chagny 1800 range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.position.x = -0.864;

  const towelBarSupportMidLeft = meshes
    .getObjectByName(MeshName.towelBarMidLeftSupport)
    .clone();
  towelBarSupportMidLeft.position.x = -0.484;

  const towelBarSupportMidRight = meshes
    .getObjectByName(MeshName.towelBarMidRightSupport)
    .clone();
  towelBarSupportMidRight.position.x = 0.479;

  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.position.x = 0.865;

  // Left Cupboard Door Bar Supports
  const leftCupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  leftCupboardDoorBarSupportLeft.name = MeshName.leftCupboardBarLeftSupport;
  leftCupboardDoorBarSupportLeft.position.x = -0.814;
  const leftCupboardDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  leftCupboardDoorBarSupportRight.name = MeshName.leftCupboardBarRightSupport;
  leftCupboardDoorBarSupportRight.position.x = -0.59;

  // Right Cupboard Bar Supports
  const rightCupboardDoorBarSupportLeft =
    leftCupboardDoorBarSupportLeft.clone();
  rightCupboardDoorBarSupportLeft.name = MeshName.rightCupboardBarLeftSupport;
  rightCupboardDoorBarSupportLeft.position.x = 0.59;
  const rightCupboardDoorBarSupportRight =
    leftCupboardDoorBarSupportRight.clone();
  rightCupboardDoorBarSupportRight.name = MeshName.rightCupboardBarRightSupport;
  rightCupboardDoorBarSupportRight.position.x = 0.814;

  // Cupboard Door Bars
  const leftCupboardDoorBar = meshes
    .getObjectByName(MeshName.classiqueCupboardDoorBar)
    .clone();
  leftCupboardDoorBar.position.x = -0.701;
  const rightCupboardDoorBar = leftCupboardDoorBar.clone();
  rightCupboardDoorBar.position.x = 0.701;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportMidLeft,
    towelBarSupportMidRight,
    towelBarSupportRight,
    leftCupboardDoorBarSupportLeft,
    leftCupboardDoorBarSupportRight,
    rightCupboardDoorBarSupportLeft,
    rightCupboardDoorBarSupportRight,
    leftCupboardDoorBar,
    rightCupboardDoorBar,
    ...common.chagnyOvenClassiqueTrim(meshes)
  );
}

/**
 * All the moderne trim parts for a Chagny 1800 range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  // Cupboard Door Bars
  const leftCupboardDoorBar = meshes
    .getObjectByName(MeshName.moderneCupboardDoorBar)
    .clone();
  leftCupboardDoorBar.position.x = -0.702;
  const rightCupboardDoorBar = leftCupboardDoorBar.clone();
  rightCupboardDoorBar.position.x = 0.702;

  // Left Cupboard Bar Supports
  const leftCupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  leftCupboardDoorBarSupportLeft.position.x = -0.82;
  const leftCupboardDoorBarSupportRight =
    leftCupboardDoorBarSupportLeft.clone();
  leftCupboardDoorBarSupportRight.position.x = -0.58;

  // Right Cupboard Bar Supports
  const rightCupboardDoorBarSupportLeft =
    leftCupboardDoorBarSupportRight.clone();
  rightCupboardDoorBarSupportLeft.position.x = 0.58;
  const rightCupboardDoorBarSupportRight =
    rightCupboardDoorBarSupportLeft.clone();
  rightCupboardDoorBarSupportRight.position.x = 0.82;

  return trim.add(
    leftCupboardDoorBar,
    rightCupboardDoorBar,
    leftCupboardDoorBarSupportLeft,
    leftCupboardDoorBarSupportRight,
    rightCupboardDoorBarSupportLeft,
    rightCupboardDoorBarSupportRight,
    ...common.chagnyOvenModerneTrim(meshes)
  );
}
