import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Oven, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, ?string>>}
   */
  selectedKnobs = {
    left: {
      optionBack: null,
      optionFront: null,
      baseBurnerBack: null,
      baseBurnerCenter: Burner.gasBurner,
      baseBurnerFront: null,
      oven: Oven.gas,
    },
    right: {
      baseBurnerFront: Burner.gasBurner,
      baseBurnerBack: Burner.gasBurner,
      optionBack: null,
      optionFront: null,
      oven: Oven.convection,
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);
  }

  threeFeuxBaseTop() {
    this.selectedKnobs.left.baseBurnerBack = null;
    this.selectedKnobs.left.baseBurnerCenter = Burner.gasBurner;
    this.selectedKnobs.left.baseBurnerFront = null;

    this.updateLeftKnobs();
  }

  fourFeuxBaseTop() {
    this.selectedKnobs.left.baseBurnerBack = Burner.gasBurner;
    this.selectedKnobs.left.baseBurnerCenter = null;
    this.selectedKnobs.left.baseBurnerFront = Burner.gasBurner;

    this.updateLeftKnobs();
  }

  /** Update the knobs on the range's left */
  updateLeftKnobs() {
    this.updateKnobRegion(Region.left, {
      maxKnobs: 5,
      offset: 0.41,
      gap: 0.11,
    });
  }

  /** Update the knobs on the range's right */
  updateRightKnobs() {
    this.updateKnobRegion(Region.right, {
      maxKnobs: 5,
      offset: 1.07,
      gap: 0.11,
    });
  }
}
