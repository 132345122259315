import { Group, Mesh, Object3D } from 'three';
import { MeshName } from '../../shared/Enums';

/**
 * Oven doors and storage drawers for a Chagny, Chagny 1400, and Chagny 1800 range
 * @param {Group} meshes - Common parts of most ranges
 * @param {number} [offset] - Adjust the trim to the left or right (in meters)
 * @returns {Object3D[]}
 */
export function chagnyOvenDoorsAndDrawers(meshes, offset = 0) {
  // Regular Oven Doors
  const rightOvenDoor = meshes.getObjectByName(MeshName.clunyOvenDoor).clone();
  rightOvenDoor.name = MeshName.rightOvenDoor;
  rightOvenDoor.translateX(offset);
  const leftOvenDoor = rightOvenDoor.clone();
  leftOvenDoor.name = MeshName.leftOvenDoor;
  leftOvenDoor.position.x = offset - 0.502;
  leftOvenDoor.translateY(-0.22);

  // Storage Drawer
  const storageDrawer = meshes
    .getObjectByName(MeshName.clunyStorageDrawer)
    .clone();
  storageDrawer.name = MeshName.storageDrawer;
  storageDrawer.translateX(offset);

  // Petite Oven Door
  const petiteOvenDoor = storageDrawer.clone();
  petiteOvenDoor.name = MeshName.petiteOvenDoor;
  petiteOvenDoor.position.x = offset - 0.502;
  petiteOvenDoor.translateY(0.39);

  // Oven Vent Covers
  const leftOvenVentCover = meshes
    .getObjectByName(MeshName.ovenVentCover)
    .clone();
  leftOvenVentCover.position.x = offset - 0.2445;
  const rightOvenVentCover = leftOvenVentCover.clone();
  rightOvenVentCover.position.x = offset + 0.2445;

  return [
    rightOvenDoor,
    leftOvenDoor,
    storageDrawer,
    petiteOvenDoor,
    leftOvenVentCover,
    rightOvenVentCover,
  ];
}

/**
 * All the oven and storage drawer classique trim parts for a Chagny, Chagny 1400,
 * and Chagny 1800 range
 * @param {Group} meshes - Common parts of most ranges
 * @param {number} [offset] - Adjust the trim to the left or right (in meters)
 * @returns {Object3D[]}
 */
export function chagnyOvenClassiqueTrim(meshes, offset = 0) {
  // Petite Oven Door Bar Supports
  const petiteOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  petiteOvenDoorBarSupportLeft.name = MeshName.petiteOvenDoorBarLeftSupport;
  petiteOvenDoorBarSupportLeft.position.x = offset - 0.46;
  const petiteOvenDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  petiteOvenDoorBarSupportRight.name = MeshName.petiteOvenDoorBarRightSupport;
  petiteOvenDoorBarSupportRight.position.x = offset - 0.042;

  // Left Oven Door Bar Supports
  const leftOvenDoorBarSupportLeft = petiteOvenDoorBarSupportLeft.clone();
  leftOvenDoorBarSupportLeft.name = MeshName.leftOvenDoorBarLeftSupport;
  leftOvenDoorBarSupportLeft.position.y += -0.22;
  const leftOvenDoorBarSupportRight = petiteOvenDoorBarSupportRight.clone();
  leftOvenDoorBarSupportRight.name = MeshName.leftOvenDoorBarRightSupport;
  leftOvenDoorBarSupportRight.position.y += -0.22;

  // Right Oven Door Bar Supports
  const rightOvenDoorBarSupportLeft = petiteOvenDoorBarSupportLeft.clone();
  rightOvenDoorBarSupportLeft.name = MeshName.rightOvenDoorBarLeftSupport;
  rightOvenDoorBarSupportLeft.position.x = offset + 0.042;
  const rightOvenDoorBarSupportRight = petiteOvenDoorBarSupportRight.clone();
  rightOvenDoorBarSupportRight.name = MeshName.rightOvenDoorBarRightSupport;
  rightOvenDoorBarSupportRight.position.x = offset + 0.46;

  // Oven Door Bars
  const petiteOvenDoorBar = meshes
    .getObjectByName(MeshName.classiqueClunyOvenDoorBar)
    .clone();
  petiteOvenDoorBar.position.x = offset - 0.251;
  const leftOvenDoorBar = petiteOvenDoorBar.clone();
  leftOvenDoorBar.position.y += -0.22;
  const rightOvenDoorBar = petiteOvenDoorBar.clone();
  rightOvenDoorBar.position.x = offset + 0.251;

  // Drawer Pulls
  const drawerPullLeft = meshes.getObjectByName(MeshName.drawerPull).clone();
  drawerPullLeft.name = MeshName.drawerLeftPull;
  drawerPullLeft.position.x = offset + 0.06;
  const drawerPullRight = drawerPullLeft.clone();
  drawerPullRight.name = MeshName.drawerRightPull;
  drawerPullRight.position.x = offset + 0.43;

  return [
    petiteOvenDoorBarSupportLeft,
    petiteOvenDoorBarSupportRight,
    leftOvenDoorBarSupportLeft,
    leftOvenDoorBarSupportRight,
    rightOvenDoorBarSupportLeft,
    rightOvenDoorBarSupportRight,
    petiteOvenDoorBar,
    rightOvenDoorBar,
    leftOvenDoorBar,
    drawerPullLeft,
    drawerPullRight,
  ];
}

/**
 * All the oven and storage drawer moderne trim parts for a Chagny, Chagny 1400,
 * and Chagny 1800 range
 * @param {Group} meshes - Common parts of most ranges
 * @param {number} [offset] - Adjust the trim to the left or right (in meters)
 * @returns {Object3D[]}
 */
export function chagnyOvenModerneTrim(meshes, offset = 0) {
  // Oven Door Bars
  const petiteOvenDoorBar = meshes
    .getObjectByName(MeshName.moderneClunyOvenDoorBar)
    .clone();
  petiteOvenDoorBar.position.x = offset - 0.251;
  const leftOvenDoorBar = petiteOvenDoorBar.clone();
  leftOvenDoorBar.position.y += -0.22;
  const rightOvenDoorBar = petiteOvenDoorBar.clone();
  rightOvenDoorBar.position.x = offset + 0.251;

  // Petite Oven Bar Supports
  const petiteOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  petiteOvenDoorBarSupportLeft.position.x = offset - 0.465;
  const petiteOvenDoorBarSupportRight = petiteOvenDoorBarSupportLeft.clone();
  petiteOvenDoorBarSupportRight.position.x = offset - 0.04;

  // Left Oven Bar Supports
  const leftOvenDoorBarSupportLeft = petiteOvenDoorBarSupportLeft.clone();
  leftOvenDoorBarSupportLeft.position.y += -0.22;
  const leftOvenDoorBarSupportRight = petiteOvenDoorBarSupportRight.clone();
  leftOvenDoorBarSupportRight.position.y += -0.22;

  // Right Oven Bar Supports
  const rightOvenDoorBarSupportLeft = petiteOvenDoorBarSupportLeft.clone();
  rightOvenDoorBarSupportLeft.position.x = offset + 0.04;
  const rightOvenDoorBarSupportRight = petiteOvenDoorBarSupportRight.clone();
  rightOvenDoorBarSupportRight.position.x = offset + 0.465;

  // Storage Drawer Lip
  const drawerLip = meshes
    .getObjectByName(MeshName.moderneClunyDrawerBottomLip)
    .clone();
  drawerLip.position.x = offset + 0.251;

  return [
    petiteOvenDoorBar,
    rightOvenDoorBar,
    leftOvenDoorBar,
    petiteOvenDoorBarSupportLeft,
    petiteOvenDoorBarSupportRight,
    leftOvenDoorBarSupportLeft,
    leftOvenDoorBarSupportRight,
    rightOvenDoorBarSupportLeft,
    rightOvenDoorBarSupportRight,
    drawerLip,
  ];
}

/**
 * Oven doors and storage drawers for a Cluny, Cluny 1400, and Cluny 1800 range
 * @param {Group} meshes - Common parts of most ranges
 * @param {number} [offset] - Adjust the trim to the left or right (in meters)
 * @returns {Object3D[]}
 */
export function clunyOvenDoorsAndDrawers(meshes, offset = 0) {
  // Oven Doors
  const rightOvenDoor = meshes.getObjectByName(MeshName.clunyOvenDoor).clone();
  rightOvenDoor.name = MeshName.rightOvenDoor;
  rightOvenDoor.translateX(offset);
  const leftOvenDoor = rightOvenDoor.clone();
  leftOvenDoor.name = MeshName.leftOvenDoor;
  leftOvenDoor.position.x = offset - 0.502;

  // Storage Drawers
  const rightStorageDrawer = meshes
    .getObjectByName(MeshName.clunyStorageDrawer)
    .clone();
  rightStorageDrawer.name = MeshName.rightStorageDrawer;
  rightStorageDrawer.translateX(offset);
  const leftStorageDrawer = rightStorageDrawer.clone();
  leftStorageDrawer.name = MeshName.leftStorageDrawer;
  leftStorageDrawer.position.x = offset - 0.502;

  // Oven Vent Covers
  const leftOvenVentCover = meshes
    .getObjectByName(MeshName.ovenVentCover)
    .clone();
  leftOvenVentCover.name = MeshName.leftOvenVentCover;
  leftOvenVentCover.position.x = offset - 0.2445;
  const rightOvenVentCover = leftOvenVentCover.clone();
  rightOvenVentCover.name = MeshName.rightOvenVentCover;
  rightOvenVentCover.position.x = offset + 0.2445;

  return [
    rightOvenDoor,
    leftOvenDoor,
    rightStorageDrawer,
    leftStorageDrawer,
    leftOvenVentCover,
    rightOvenVentCover,
  ];
}

/**
 * All the oven and storage drawer classique trim parts for a Cluny, Cluny 1400,
 * and Cluny 1800 range
 * @param {Group} meshes - Common parts of most ranges
 * @param {number} [offset] - Adjust the trim to the left or right (in meters)
 * @returns {Object3D[]}
 */
export function clunyOvenClassiqueTrim(meshes, offset = 0) {
  // Left Oven Door Bar Supports
  const leftOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  leftOvenDoorBarSupportLeft.name = MeshName.leftOvenDoorBarLeftSupport;
  leftOvenDoorBarSupportLeft.position.x = offset - 0.46;
  const leftOvenDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  leftOvenDoorBarSupportRight.name = MeshName.leftOvenDoorBarRightSupport;
  leftOvenDoorBarSupportRight.position.x = offset - 0.042;

  // Right Oven Door Bar Supports
  const rightOvenDoorBarSupportLeft = leftOvenDoorBarSupportLeft.clone();
  rightOvenDoorBarSupportLeft.name = MeshName.rightOvenDoorBarLeftSupport;
  rightOvenDoorBarSupportLeft.position.x = offset + 0.042;
  const rightOvenDoorBarSupportRight = leftOvenDoorBarSupportRight.clone();
  rightOvenDoorBarSupportRight.name = MeshName.rightOvenDoorBarRightSupport;
  rightOvenDoorBarSupportRight.position.x = offset + 0.46;

  // Oven Door Bars
  const leftOvenDoorBar = meshes
    .getObjectByName(MeshName.classiqueClunyOvenDoorBar)
    .clone();
  leftOvenDoorBar.position.x = offset - 0.251;
  const rightOvenDoorBar = leftOvenDoorBar.clone();
  rightOvenDoorBar.position.x = offset + 0.251;

  // Left Drawer Pulls
  const leftDrawerPullLeft = meshes
    .getObjectByName(MeshName.drawerPull)
    .clone();
  leftDrawerPullLeft.name = MeshName.leftDrawerLeftPull;
  leftDrawerPullLeft.position.x = offset - 0.429;
  const leftDrawerPullRight = leftDrawerPullLeft.clone();
  leftDrawerPullRight.name = MeshName.leftDrawerRightPull;
  leftDrawerPullRight.position.x = offset - 0.082;

  // Right Drawer Pulls
  const rightDrawerPullLeft = leftDrawerPullRight.clone();
  rightDrawerPullLeft.name = MeshName.rightDrawerLeftPull;
  rightDrawerPullLeft.position.x = offset + 0.078;
  const rightDrawerPullRight = leftDrawerPullRight.clone();
  rightDrawerPullRight.name = MeshName.rightDrawerRightPull;
  rightDrawerPullRight.position.x = offset + 0.42;

  return [
    leftOvenDoorBarSupportLeft,
    leftOvenDoorBarSupportRight,
    rightOvenDoorBarSupportLeft,
    rightOvenDoorBarSupportRight,
    leftOvenDoorBar,
    rightOvenDoorBar,
    leftDrawerPullLeft,
    leftDrawerPullRight,
    rightDrawerPullLeft,
    rightDrawerPullRight,
  ];
}

/**
 * All the oven and storage drawer moderne trim parts for a Cluny, Cluny 1400,
 * and Cluny 1800 range
 * @param {Group} meshes - Common parts of most ranges
 * @param {number} [offset] - Adjust the trim to the left or right (in meters)
 * @returns {Object3D[]}
 */
export function clunyOvenModerneTrim(meshes, offset = 0) {
  // Oven Door Bars
  const leftOvenDoorBar = meshes
    .getObjectByName(MeshName.moderneClunyOvenDoorBar)
    .clone();
  leftOvenDoorBar.position.x = offset - 0.251;
  const rightOvenDoorBar = leftOvenDoorBar.clone();
  rightOvenDoorBar.position.x = offset + 0.251;

  // Left Oven Bar Supports
  const leftOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  leftOvenDoorBarSupportLeft.position.x = offset - 0.465;
  const leftOvenDoorBarSupportRight = leftOvenDoorBarSupportLeft.clone();
  leftOvenDoorBarSupportRight.position.x = offset - 0.04;

  // Right Oven Bar Supports
  const rightOvenDoorBarSupportLeft = leftOvenDoorBarSupportRight.clone();
  rightOvenDoorBarSupportLeft.position.x = offset + 0.04;
  const rightOvenDoorBarSupportRight = rightOvenDoorBarSupportLeft.clone();
  rightOvenDoorBarSupportRight.position.x = offset + 0.465;

  // Storage Drawer Lips
  const leftDrawerLip = meshes
    .getObjectByName(MeshName.moderneClunyDrawerBottomLip)
    .clone();
  leftDrawerLip.position.x = offset - 0.251;
  const rightDrawerLip = leftDrawerLip.clone();
  rightDrawerLip.position.x = offset + 0.251;

  return [
    leftOvenDoorBar,
    rightOvenDoorBar,
    leftOvenDoorBarSupportLeft,
    leftOvenDoorBarSupportRight,
    rightOvenDoorBarSupportLeft,
    rightOvenDoorBarSupportRight,
    leftDrawerLip,
    rightDrawerLip,
  ];
}

/**
 * Oven doors and storage drawers for a Sully, Sully 1800, and Sully 2200 range
 * @param {Group} meshes - Common parts of most ranges
 * @param {number} [offset] - Adjust the trim to the left or right (in meters)
 * @returns {Object3D[]}
 */
export function sullyOvenDoorsAndDrawers(meshes, offset = 0) {
  // Oven Doors
  const leftOvenDoor = meshes.getObjectByName(MeshName.sullyOvenDoor).clone();
  leftOvenDoor.name = MeshName.leftOvenDoor;
  leftOvenDoor.translateX(offset);
  const rightOvenDoor = leftOvenDoor.clone();
  rightOvenDoor.name = MeshName.rightOvenDoor;
  rightOvenDoor.position.x = offset + 0.351;

  // Storage Drawers
  const leftStorageDrawer = meshes
    .getObjectByName(MeshName.sullyStorageDrawer)
    .clone();
  leftStorageDrawer.name = MeshName.leftStorageDrawer;
  leftStorageDrawer.translateX(offset);
  const rightStorageDrawer = leftStorageDrawer.clone();
  rightStorageDrawer.name = MeshName.rightStorageDrawer;
  rightStorageDrawer.position.x = offset + 0.351;

  // Standard Oven Vent Covers
  const leftOvenVentCover = meshes
    .getObjectByName(MeshName.ovenVentCover)
    .clone();
  leftOvenVentCover.name = MeshName.leftOvenVentCover;
  leftOvenVentCover.translateX(offset);
  const rightOvenVentCover = leftOvenVentCover.clone();
  rightOvenVentCover.name = MeshName.rightOvenVentCover;
  rightOvenVentCover.position.x = offset + 0.3445;

  return [
    leftOvenDoor,
    rightOvenDoor,
    leftStorageDrawer,
    rightStorageDrawer,
    leftOvenDoor,
    leftOvenVentCover,
    rightOvenVentCover,
  ];
}

/**
 * All the oven and storage drawer classique trim parts for a Sully, Sully 1800,
 * and Sully 2200 range
 * @param {Group} meshes - Common parts of most ranges
 * @param {number} [offset] - Adjust the trim to the left or right (in meters)
 * @returns {Object3D[]}
 */
export function sullyOvenClassiqueTrim(meshes, offset = 0) {
  // Left Oven Door Bar Supports
  const leftOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  leftOvenDoorBarSupportLeft.name = MeshName.leftOvenDoorBarLeftSupport;
  leftOvenDoorBarSupportLeft.position.x += offset;
  const leftOvenDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  leftOvenDoorBarSupportRight.name = MeshName.leftOvenDoorBarRightSupport;
  leftOvenDoorBarSupportRight.position.x += offset;

  // Right Oven Door Bar Supports
  const rightOvenDoorBarSupportLeft = leftOvenDoorBarSupportLeft.clone();
  rightOvenDoorBarSupportLeft.name = MeshName.rightOvenDoorBarLeftSupport;
  rightOvenDoorBarSupportLeft.position.x = offset + 0.042;
  const rightOvenDoorBarSupportRight = leftOvenDoorBarSupportRight.clone();
  rightOvenDoorBarSupportRight.name = MeshName.rightOvenDoorBarRightSupport;
  rightOvenDoorBarSupportRight.position.x = offset + 0.66;

  // Oven Door Bars
  const leftOvenDoorBar = meshes
    .getObjectByName(MeshName.classiqueSullyOvenDoorBar)
    .clone();
  leftOvenDoorBar.position.x += offset;
  const rightOvenDoorBar = leftOvenDoorBar.clone();
  rightOvenDoorBar.position.x = offset + 0.351;

  // Left Drawer Pulls
  const leftDrawerPullRight = meshes
    .getObjectByName(MeshName.drawerPull)
    .clone();
  leftDrawerPullRight.name = MeshName.leftDrawerRightPull;
  leftDrawerPullRight.position.x += offset;
  const leftDrawerPullLeft = leftDrawerPullRight.clone();
  leftDrawerPullLeft.name = MeshName.leftDrawerLeftPull;
  leftDrawerPullLeft.position.x = offset - 0.6;

  // Right Drawer Pulls
  const rightDrawerPullLeft = leftDrawerPullRight.clone();
  rightDrawerPullLeft.name = MeshName.rightDrawerLeftPull;
  rightDrawerPullLeft.position.x = offset + 0.1025;
  const rightDrawerPullRight = leftDrawerPullRight.clone();
  rightDrawerPullRight.name = MeshName.rightDrawerRightPull;
  rightDrawerPullRight.position.x = offset + 0.6;

  return [
    leftOvenDoorBarSupportLeft,
    leftOvenDoorBarSupportRight,
    rightOvenDoorBarSupportLeft,
    rightOvenDoorBarSupportRight,
    leftOvenDoorBar,
    rightOvenDoorBar,
    leftDrawerPullLeft,
    leftDrawerPullRight,
    rightDrawerPullLeft,
    rightDrawerPullRight,
  ];
}

/**
 * All the oven and storage drawer moderne trim parts for a Sully, Sully 1800,
 * and Sully 2200 range
 * @param {Group} meshes - Common parts of most ranges
 * @param {number} [offset] - Adjust the trim to the left or right (in meters)
 * @returns {Object3D[]}
 */
export function sullyOvenModerneTrim(meshes, offset = 0) {
  // Left Oven Bar Supports
  const leftOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  leftOvenDoorBarSupportLeft.position.x = offset - 0.66;
  const leftOvenDoorBarSupportRight = leftOvenDoorBarSupportLeft.clone();
  leftOvenDoorBarSupportRight.position.x = offset - 0.04;

  // Right Oven Bar Supports
  const rightOvenDoorBarSupportLeft = leftOvenDoorBarSupportRight.clone();
  rightOvenDoorBarSupportLeft.position.x = offset + 0.04;
  const rightOvenDoorBarSupportRight = rightOvenDoorBarSupportLeft.clone();
  rightOvenDoorBarSupportRight.position.x = offset + 0.66;

  // Oven Door Bars
  const leftOvenDoorBar = meshes
    .getObjectByName(MeshName.moderneSullyOvenDoorBar)
    .clone();
  leftOvenDoorBar.translateX(offset);
  const rightOvenDoorBar = leftOvenDoorBar.clone();
  rightOvenDoorBar.position.x = offset + 0.351;

  // Storage Drawer Lips
  const leftDrawerLip = meshes
    .getObjectByName(MeshName.moderneSullyDrawerBottomLip)
    .clone();
  leftDrawerLip.translateX(offset);
  const rightDrawerLip = leftDrawerLip.clone();
  rightDrawerLip.position.x = offset + 0.351;

  return [
    leftOvenDoorBarSupportLeft,
    leftOvenDoorBarSupportRight,
    rightOvenDoorBarSupportLeft,
    rightOvenDoorBarSupportRight,
    leftOvenDoorBar,
    rightOvenDoorBar,
    leftDrawerLip,
    rightDrawerLip,
  ];
}
