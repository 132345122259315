import { MeshStandardMaterial } from 'three';
import { AssetLoader } from './AssetLoader';

export class Colors {
  // Range Body Colors
  anthracite;
  ardoise;
  armor;
  britishRacingGreen;
  burgundyRed;
  chantilly;
  chocolat;
  coralBlue;
  delftBlue;
  englishCreme;
  faience;
  frangipane;
  frenchBlue;
  griotte;
  ivory;
  mandarine;
  marronGlace;
  matteBlack;
  shinyBlack;
  olive;
  portugueseBlue;
  provenceYellow;
  roseQuartz;
  terracotta;
  tilleul;
  vertSilice;
  vertSologne;
  white;

  // Other Colors
  amberLight;
  beigeDial;
  blackBody;
  brownDial;

  /** @param {AssetLoader} assets */
  constructor(assets) {
    //*****************  Range Body Colors  *****************//

    this.anthracite = new MeshStandardMaterial({
      color: 0x383e42,
      metalness: 0.5,
      roughness: 0.1,
    });

    this.ardoise = new MeshStandardMaterial({
      color: 0x615f5d,
      metalness: 0.5,
      roughness: 0.1,
    });

    this.armor = new MeshStandardMaterial({
      color: 0x7a8b99,
      metalness: 0.5,
      roughness: 0.2,
    });

    this.britishRacingGreen = new MeshStandardMaterial({
      color: 0x2d4220,
      metalness: 0.5,
      roughness: 0.2,
    });

    this.burgundyRed = new MeshStandardMaterial({
      // map: assets.cherryRedTexture,
      color: 0x6b0000,
      metalness: 0.41,
      roughness: 0.3,
    });

    this.chantilly = new MeshStandardMaterial({
      color: 0xdbd6c2,
      metalness: 0.03,
      roughness: 0.15,
    });

    this.chocolat = new MeshStandardMaterial({
      color: 0x402c24,
      metalness: 0.3,
      roughness: 0.15,
    });

    this.coralBlue = new MeshStandardMaterial({
      color: 0x43a2ab,
      metalness: 0.45,
      roughness: 0.1,
    });

    this.delftBlue = new MeshStandardMaterial({
      color: 0x97b0c4,
      metalness: 0.45,
      roughness: 0.1,
    });

    this.englishCreme = new MeshStandardMaterial({
      color: 0xffeecc,
      metalness: 0.55,
      roughness: 0.25,
    });

    this.faience = new MeshStandardMaterial({
      color: 0xcbdae7,
      metalness: 0.55,
      roughness: 0.25,
    });

    this.frangipane = new MeshStandardMaterial({
      color: 0xb4a588,
      metalness: 0.55,
      roughness: 0.55,
    });

    this.frenchBlue = new MeshStandardMaterial({
      color: 0x121c64,
      metalness: 0.61,
      roughness: 0.23,
    });

    this.griotte = new MeshStandardMaterial({
      color: 0x801f11,
      metalness: 0.45,
      roughness: 0.3,
    });

    this.ivory = new MeshStandardMaterial({
      color: 0xfff1d6,
      metalness: 0.45,
      roughness: 0.3,
    });

    this.mandarine = new MeshStandardMaterial({
      color: 0xd16c00,
      metalness: 0.3,
      roughness: 0.25,
    });

    this.marronGlace = new MeshStandardMaterial({
      color: 0x7b6251,
      metalness: 0.3,
      roughness: 0.25,
    });

    this.matteBlack = new MeshStandardMaterial({
      color: 0x171717,
      metalness: 0,
      roughness: 1,
    });

    this.shinyBlack = new MeshStandardMaterial({
      color: 0x171717,
      metalness: 0.3,
      roughness: 0.9,
    });

    this.olive = new MeshStandardMaterial({
      color: 0x5a5520,
      metalness: 0.3,
      roughness: 0.9,
    });

    this.plum = new MeshStandardMaterial({
      color: 0x503a46,
      metalness: 0.3,
      roughness: 0.9,
    });

    this.portugueseBlue = new MeshStandardMaterial({
      color: 0x18279a,
      metalness: 0.6,
      roughness: 0.2,
    });

    this.provenceYellow = new MeshStandardMaterial({
      color: 0xeba205,
      metalness: 0.8,
      roughness: 0.95,
    });

    this.roseQuartz = new MeshStandardMaterial({
      color: 0xffe5fe,
      metalness: 0.5,
      roughness: 0.1,
    });

    this.terracotta = new MeshStandardMaterial({
      color: 0x994515,
      metalness: 0.5,
      roughness: 0.15,
    });

    this.tilleul = new MeshStandardMaterial({
      color: 0xcdd9ab,
      metalness: 0.5,
      roughness: 0.15,
    });

    this.vertSilice = new MeshStandardMaterial({
      color: 0xcee3df,
      metalness: 0.32,
      roughness: 0.5,
    });

    this.vertSologne = new MeshStandardMaterial({
      color: 0x868456,
      metalness: 0.3,
      roughness: 0.1,
    });

    this.white = new MeshStandardMaterial({
      color: 0xebebeb,
      metalness: 0.3,
      roughness: 0.1,
    });

    //*******************  Other Colors  ********************//

    this.beigeDial = new MeshStandardMaterial({
      color: 0xfaf0e6,
      metalness: 0.7,
      roughness: 0.5,
    });

    this.blackBody = new MeshStandardMaterial({
      color: 0x000000,
      metalness: 0.7,
      roughness: 0.5,
    });

    this.brownDial = new MeshStandardMaterial({
      color: 0x7c7062,
      metalness: 0.7,
      roughness: 0.5,
    });

    this.amberLight = new MeshStandardMaterial({
      color: 0x73431f,
      metalness: 0.3,
      roughness: 0.9,
    });

    this.redLight = new MeshStandardMaterial({
      color: 0x5f1c25,
      metalness: 0.3,
      roughness: 0.9,
    });
  }
}
