import { Group, Object3D } from 'three';
import * as common from './common.js';
import { MeshName } from '../../shared/Enums.js';

/**
 * Various external (common) parts of the Sully 2200 range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Cupboard Doors
  const leftCupboardDoor = meshes
    .getObjectByName(MeshName.cupboardDoor)
    .clone();
  leftCupboardDoor.name = MeshName.leftCupboardDoor;
  const rightCupboardDoor = leftCupboardDoor.clone();
  rightCupboardDoor.name = MeshName.rightCupboardDoor;
  rightCupboardDoor.position.x = 0.902;

  // Cupboard Vent Covers
  const leftCupboardVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCover)
    .clone();
  leftCupboardVentCover.name = MeshName.leftCupboardVentCover;
  const rightCupboardVentCover = leftCupboardVentCover.clone();
  rightCupboardVentCover.name = MeshName.rightCupboardVentCover;
  rightCupboardVentCover.position.x = 0.9;

  // Mini Oven Vent Cover
  const leftOvenMiniVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCover)
    .clone();
  leftOvenMiniVentCover.name = MeshName.leftOvenMiniVentCover;
  leftOvenMiniVentCover.position.x = -0.494;
  leftOvenMiniVentCover.visible = false;
  const rightOvenMiniVentCover = leftOvenMiniVentCover.clone();
  rightOvenMiniVentCover.name = MeshName.rightOvenMiniVentCover;
  rightOvenMiniVentCover.position.x = 0.494;
  rightOvenMiniVentCover.visible = false;

  return [
    leftCupboardDoor,
    rightCupboardDoor,
    leftCupboardVentCover,
    rightCupboardVentCover,
    leftOvenMiniVentCover,
    rightOvenMiniVentCover,
    ...common.sullyOvenDoorsAndDrawers(meshes),
  ];
}

/**
 * All the classique trim parts for a Sully 2200 range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  const towelBarSupportMidLeft = meshes
    .getObjectByName(MeshName.towelBarMidLeftSupport)
    .clone();
  const towelBarSupportCenter = meshes
    .getObjectByName(MeshName.towelBarCenterSupport)
    .clone();
  const towelBarSupportMidRight = meshes
    .getObjectByName(MeshName.towelBarMidRightSupport)
    .clone();
  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();

  // Left Cupboard Door Bar Supports
  const leftCupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  leftCupboardDoorBarSupportLeft.name = MeshName.leftCupboardBarLeftSupport;
  leftCupboardDoorBarSupportLeft.position.x = -1.014;
  const leftCupboardDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  leftCupboardDoorBarSupportRight.name = MeshName.leftCupboardBarRightSupport;
  leftCupboardDoorBarSupportRight.position.x = -0.79;

  // Right Cupboard Door Bar Supports
  const rightCupboardDoorBarSupportLeft =
    leftCupboardDoorBarSupportLeft.clone();
  rightCupboardDoorBarSupportLeft.name = MeshName.rightCupboardBarLeftSupport;
  rightCupboardDoorBarSupportLeft.position.x = 0.79;
  const rightCupboardDoorBarSupportRight =
    leftCupboardDoorBarSupportRight.clone();
  rightCupboardDoorBarSupportRight.name = MeshName.rightCupboardBarRightSupport;
  rightCupboardDoorBarSupportRight.position.x = 1.014;

  // Cupboard Door Bars
  const leftCupboardDoorBar = meshes
    .getObjectByName(MeshName.classiqueCupboardDoorBar)
    .clone();
  const rightCupboardDoorBar = leftCupboardDoorBar.clone();
  rightCupboardDoorBar.position.x = 0.9;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportMidLeft,
    towelBarSupportCenter,
    towelBarSupportMidRight,
    towelBarSupportRight,
    leftCupboardDoorBarSupportLeft,
    leftCupboardDoorBarSupportRight,
    rightCupboardDoorBarSupportLeft,
    rightCupboardDoorBarSupportRight,
    leftCupboardDoorBar,
    rightCupboardDoorBar,
    ...common.sullyOvenClassiqueTrim(meshes)
  );
}

/**
 * All the moderne trim parts for a Sully 2200 range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  // Left Cupboard Bar Supports
  const leftCupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  leftCupboardDoorBarSupportLeft.position.x = -1.02;
  const leftCupboardDoorBarSupportRight =
    leftCupboardDoorBarSupportLeft.clone();
  leftCupboardDoorBarSupportRight.position.x = -0.78;

  // Right Cupboard Bar Supports
  const rightCupboardDoorBarSupportLeft =
    leftCupboardDoorBarSupportLeft.clone();
  rightCupboardDoorBarSupportLeft.position.x = 0.78;
  const rightCupboardDoorBarSupportRight =
    rightCupboardDoorBarSupportLeft.clone();
  rightCupboardDoorBarSupportRight.position.x = 1.02;

  // Cupboard Door Bars
  const leftCupboardDoorBar = meshes
    .getObjectByName(MeshName.moderneCupboardDoorBar)
    .clone();
  const rightCupboardDoorBar = leftCupboardDoorBar.clone();
  rightCupboardDoorBar.position.x = 0.902;

  return trim.add(
    leftCupboardDoorBarSupportLeft,
    leftCupboardDoorBarSupportRight,
    rightCupboardDoorBarSupportLeft,
    rightCupboardDoorBarSupportRight,
    leftCupboardDoorBar,
    rightCupboardDoorBar,
    ...common.sullyOvenModerneTrim(meshes)
  );
}
