import { BufferGeometry, Group, Mesh } from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { BaseSSRangeTop } from '../shared/BaseSSRangeTop';
import { RangeTopOptions } from '../shared/RangeTopOptions';
import { Burner, MeshName } from '../../shared/Enums';

export class SSRangeTop extends BaseSSRangeTop {
  /**
   * An outer rim geometry for a Cluny 1800 range top
   * @type {BufferGeometry}
   */
  #topRimGeo;

  /** @type {Mesh} */
  #topRimMesh;

  /**
   * @typedef TopState
   * @type {object}
   * @property {boolean} [fourFeux] - is the range top 4-feux
   * @property {string} [option1] - the first (leftmost) range top option
   * @property {string} [option2] - the second (rightmost) range top option
   */

  /**
   * Configured range top options
   * @type {TopState}
   */
  #state = {};

  /**
   * @param {Group} range
   * @param {RangeTopOptions} rangeTopOptions
   */
  constructor(range, rangeTopOptions) {
    super(rangeTopOptions, 0.2);

    this.#makeTopRimGeometry(range);
  }

  /**
   * The fully configured stainless steel range top - based on range top options
   * @param {TopState} newState
   * @returns {Mesh}
   */
  assembleSSRangeTop(newState) {
    this.#state = { ...this.#state, ...newState };

    const geometries = [
      this.#topRimGeo,
      this.#option1Surface(),
      this.#spacerOption1toBase(),
      this.#leftBaseSurface(),
      this.#spacerLeftToMiddleBase(),
      this.#middleBaseSurface(),
      this.#spacerMiddleToRightBase(),
      this.#rightBaseSurface(),
      this.#spacerBaseToOption2(),
      this.#option2Surface(),
    ];

    const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries);

    const rangeTop = new Mesh(mergedGeometry, this.#topRimMesh.material);
    rangeTop.name = 'range-top';
    rangeTop.receiveShadow = true;

    return rangeTop;
  }

  /**
   * A range top surface geometry for the first range top option
   * @returns {BufferGeometry}
   */
  #option1Surface() {
    const offset = 0;

    switch (this.#state.option1) {
      case Burner.two11kBurners:
      case Burner.two15kBurners:
        return this.wide2BurnerPond(offset);

      case Burner.one18kBurner:
      case Burner.traditionalSimmerPlate:
      case Burner.multiCooker:
        return this.oneBurnerPond(offset);

      case Burner.flameGrill:
        return this.flameGrillPond(offset);

      default:
        return this.wideWorkstation(offset);
    }
  }

  /**
   * A range top spacer geometry between the first option and the base burners
   * @returns {BufferGeometry}
   */
  #spacerOption1toBase() {
    return this.spacer23_5mm(0.39);
  }

  /**
   * A range top surface geometry for the base left 2 burners
   * @returns {BufferGeometry}
   */
  #leftBaseSurface() {
    return this.narrow2BurnerPond(0.4135);
  }

  /**
   * A range top spacer geometry between the left base burners and central burner
   * @returns {BufferGeometry}
   */
  #spacerLeftToMiddleBase() {
    return this.spacer23_5mm(0.6735);
  }

  /**
   * A range top surface geometry for the middle 18k burner
   * @returns {BufferGeometry}
   */
  #middleBaseSurface() {
    return this.oneBurnerPond(0.697);
  }

  /**
   * A range top spacer geometry between the central base burner and right burners
   * @returns {BufferGeometry}
   */
  #spacerMiddleToRightBase() {
    return this.spacer23_5mm(1.087);
  }

  /**
   * A range top surface geometry for the base right 2 burners
   * @returns {BufferGeometry}
   */
  #rightBaseSurface() {
    return this.narrow2BurnerPond(1.1105);
  }

  /**
   * A range top spacer geometry between the base burners and the second option
   * @returns {BufferGeometry}
   */
  #spacerBaseToOption2() {
    return this.spacer23_5mm(1.3705);
  }

  /**
   * A range top surface geometry for the fourth range top option
   * @returns {BufferGeometry}
   */
  #option2Surface() {
    const offset = 1.394;

    switch (this.#state.option2) {
      case Burner.two11kBurners:
      case Burner.two15kBurners:
        return this.wide2BurnerPond(offset);

      case Burner.one18kBurner:
      case Burner.traditionalSimmerPlate:
      case Burner.multiCooker:
        return this.oneBurnerPond(offset);

      case Burner.flameGrill:
        return this.flameGrillPond(offset);

      default:
        return this.wideWorkstation(offset);
    }
  }

  /**
   * Create a geometry for the outer rim of the range top
   * @param {Group} range
   */
  #makeTopRimGeometry(range) {
    // @ts-ignore
    this.#topRimMesh = range.getObjectByName(MeshName.rangeTopRim1800);

    this.#topRimGeo = this.#topRimMesh.geometry.clone();
    this.#topRimGeo.applyQuaternion(this.#topRimMesh.quaternion);
    this.#topRimGeo.translate(
      this.#topRimMesh.position.x,
      this.#topRimMesh.position.y,
      this.#topRimMesh.position.z
    );
  }
}
