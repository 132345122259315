import { EquirectangularReflectionMapping } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

// @ts-ignore
import hdrFile from '/assets/envmap/MR_INT-001_NaturalStudio_NAD.hdr?url';

export function loadEnvMap(scene, callback) {
  new RGBELoader().load(hdrFile, function (texture) {
    texture.mapping = EquirectangularReflectionMapping;
    scene.environment = texture;
    // scene.environmentRotation.set(0.1, 0, 0);
    callback();
  });
}
