import { Group, Object3D } from 'three';
import * as common from './common.js';
import { MeshName } from '../../shared/Enums.js';

/**
 * Various external (common) parts of the Sully 1800 (right) range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Right Cupboard Door
  const rightCupboardDoor = meshes
    .getObjectByName(MeshName.cupboardDoor)
    .clone();
  rightCupboardDoor.name = MeshName.rightCupboardDoor;
  rightCupboardDoor.translateX(1.604);

  // Right Cupboard Vent Cover
  const rightCupboardVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCover)
    .clone();
  rightCupboardVentCover.name = MeshName.rightCupboardVentCover;
  rightCupboardVentCover.translateX(1.596);

  // Mini Oven Vent Cover
  const leftOvenMiniVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCover)
    .clone();
  leftOvenMiniVentCover.name = MeshName.leftOvenMiniVentCover;
  leftOvenMiniVentCover.position.x = -0.698;
  leftOvenMiniVentCover.visible = false;
  const rightOvenMiniVentCover = leftOvenMiniVentCover.clone();
  rightOvenMiniVentCover.name = MeshName.rightOvenMiniVentCover;
  rightOvenMiniVentCover.position.x = 0.284;
  rightOvenMiniVentCover.visible = false;

  return [
    rightCupboardDoor,
    rightCupboardVentCover,
    leftOvenMiniVentCover,
    rightOvenMiniVentCover,
    ...common.sullyOvenDoorsAndDrawers(meshes, -0.2),
  ];
}

/**
 * All the classique trim parts for a Sully 1800 (right) range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.translateX(0.2);
  const towelBarSupportCenter = meshes
    .getObjectByName(MeshName.towelBarCenterSupport)
    .clone();
  towelBarSupportCenter.translateX(0.2);
  const towelBarSupportMidRight = meshes
    .getObjectByName(MeshName.towelBarMidRightSupport)
    .clone();
  towelBarSupportMidRight.translateX(-0.2);
  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.translateX(0.2);

  // Right Cupboard Door Bar Supports
  const rightCupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  rightCupboardDoorBarSupportLeft.name = MeshName.rightCupboardBarLeftSupport;
  rightCupboardDoorBarSupportLeft.position.x = 0.59;
  const rightCupboardDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  rightCupboardDoorBarSupportRight.name = MeshName.rightCupboardBarRightSupport;
  rightCupboardDoorBarSupportRight.position.x = 0.814;

  // Cupboard Door Bars
  const rightCupboardDoorBar = meshes
    .getObjectByName(MeshName.classiqueCupboardDoorBar)
    .clone();
  rightCupboardDoorBar.translateX(1.6);

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportCenter,
    towelBarSupportMidRight,
    towelBarSupportRight,
    rightCupboardDoorBarSupportLeft,
    rightCupboardDoorBarSupportRight,
    rightCupboardDoorBar,
    ...common.sullyOvenClassiqueTrim(meshes, -0.2)
  );
}

/**
 * All the moderne trim parts for a Sully 1800 (right) range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  // Right Cupboard Bar Supports
  const rightCupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  rightCupboardDoorBarSupportLeft.position.x = 0.58;
  const rightCupboardDoorBarSupportRight =
    rightCupboardDoorBarSupportLeft.clone();
  rightCupboardDoorBarSupportRight.position.x = 0.82;

  // Right Cupboard Door Bar
  const rightCupboardDoorBar = meshes
    .getObjectByName(MeshName.moderneCupboardDoorBar)
    .clone();
  rightCupboardDoorBar.translateX(1.6);

  return trim.add(
    rightCupboardDoorBarSupportLeft,
    rightCupboardDoorBarSupportRight,
    rightCupboardDoorBar,
    ...common.sullyOvenModerneTrim(meshes, -0.2)
  );
}
