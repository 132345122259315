import { Group, Object3D } from 'three';

import * as beaune from './beaune.js';
import * as bussy from './bussy.js';
import * as chagny from './chagny.js';
import * as chagny1400left from './chagny1400left.js';
import * as chagny1400right from './chagny1400right.js';
import * as chagny1800 from './chagny1800.js';
import * as chambertin from './chambertin.js';
import * as chassagne from './chassagne.js';
import * as cluny from './cluny.js';
import * as cluny1400left from './cluny1400left.js';
import * as cluny1400right from './cluny1400right.js';
import * as cluny1800 from './cluny1800.js';
import * as cormatin from './cormatin.js';
import * as fontenay from './fontenay.js';
import * as rully from './rully.js';
import * as sully from './sully.js';
import * as sully2200 from './sully2200.js';
import * as sully1800left from './sully1800left.js';
import * as sully1800right from './sully1800right.js';
import * as volnay from './volnay.js';
import * as vougeot from './vougeot.js';

import { AssetLoader } from '../../shared/AssetLoader';
import { MeshName } from '../../shared/Enums.js';
import { Materials } from '../../shared/Materials';

class RangeParts {
  /** All the common parts for any Lacanche range */
  #parts = new Group();

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   */
  constructor(assets, materials) {
    // Load 3D models
    while (assets.rangePartsData.scene.children.length) {
      this.#parts.add(assets.rangePartsData.scene.children[0]);
    }

    this.#applyMaterials(materials);
    this.#setupShadows();
  }

  /**
   * Various external (common) parts of a Beaune range
   * @returns {Object3D[]}
   */
  beauneParts() {
    return beaune.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Beaune range
   * @returns {Group}
   */
  beauneClassiqueTrim() {
    return beaune.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Beaune range
   * @returns {Group}
   */
  beauneModerneTrim() {
    return beaune.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Bussy range
   * @returns {Object3D[]}
   */
  bussyParts() {
    return bussy.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Bussy range
   * @returns {Group}
   */
  bussyClassiqueTrim() {
    return bussy.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Bussy range
   * @returns {Group}
   */
  bussyModerneTrim() {
    return bussy.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Chagny range
   * @returns {Object3D[]}
   */
  chagnyParts() {
    return chagny.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Chagny range
   * @returns {Group}
   */
  chagnyClassiqueTrim() {
    return chagny.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Chagny range
   * @returns {Group}
   */
  chagnyModerneTrim() {
    return chagny.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Chagny 1400 (left) range
   * @returns {Object3D[]}
   */
  chagny1400LeftParts() {
    return chagny1400left.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Chagny 1400 (left) range
   * @returns {Group}
   */
  chagny1400LeftClassiqueTrim() {
    return chagny1400left.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Chagny 1400 (left) range
   * @returns {Group}
   */
  chagny1400LeftModerneTrim() {
    return chagny1400left.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Chagny 1400 (right) range
   * @returns {Object3D[]}
   */
  chagny1400RightParts() {
    return chagny1400right.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Chagny 1400 (right) range
   * @returns {Group}
   */
  chagny1400RightClassiqueTrim() {
    return chagny1400right.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Chagny 1400 (right) range
   * @returns {Group}
   */
  chagny1400RightModerneTrim() {
    return chagny1400right.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Chagny 1800 range
   * @returns {Object3D[]}
   */
  chagny1800Parts() {
    return chagny1800.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Chagny 1800 range
   * @returns {Group}
   */
  chagny1800ClassiqueTrim() {
    return chagny1800.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Chagny 1800 range
   * @returns {Group}
   */
  chagny1800ModerneTrim() {
    return chagny1800.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Chambertin range
   * @returns {Object3D[]}
   */
  chambertinParts() {
    return chambertin.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Chambertin range
   * @returns {Group}
   */
  chambertinClassiqueTrim() {
    return chambertin.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Chambertin range
   * @returns {Group}
   */
  chambertinModerneTrim() {
    return chambertin.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Chassagne range
   * @returns {Object3D[]}
   */
  chassagneParts() {
    return chassagne.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Chassagne range
   * @returns {Group}
   */
  chassagneClassiqueTrim() {
    return chassagne.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Chassagne range
   * @returns {Group}
   */
  chassagneModerneTrim() {
    return chassagne.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Cluny range
   * @returns {Object3D[]}
   */
  clunyParts() {
    return cluny.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Cluny range
   * @returns {Group}
   */
  clunyClassiqueTrim() {
    return cluny.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Cluny range
   * @returns {Group}
   */
  clunyModerneTrim() {
    return cluny.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Cluny 1400 (left) range
   * @returns {Object3D[]}
   */
  cluny1400LeftParts() {
    return cluny1400left.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Cluny 1400 (left) range
   * @returns {Group}
   */
  cluny1400LeftClassiqueTrim() {
    return cluny1400left.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Cluny 1400 (left) range
   * @returns {Group}
   */
  cluny1400LeftModerneTrim() {
    return cluny1400left.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Cluny 1400 (right) range
   * @returns {Object3D[]}
   */
  cluny1400RightParts() {
    return cluny1400right.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Cluny 1400 (right) range
   * @returns {Group}
   */
  cluny1400RightClassiqueTrim() {
    return cluny1400right.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Cluny 1400 (right) range
   * @returns {Group}
   */
  cluny1400RightModerneTrim() {
    return cluny1400right.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Cluny 1800 range
   * @returns {Object3D[]}
   */
  cluny1800Parts() {
    return cluny1800.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Cluny 1800 range
   * @returns {Group}
   */
  cluny1800ClassiqueTrim() {
    return cluny1800.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Cluny 1800 range
   * @returns {Group}
   */
  cluny1800ModerneTrim() {
    return cluny1800.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Cormatin range
   * @returns {Object3D[]}
   */
  cormatinParts() {
    return cormatin.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Cormatin range
   * @returns {Group}
   */
  cormatinClassiqueTrim() {
    return cormatin.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Cormatin range
   * @returns {Group}
   */
  cormatinModerneTrim() {
    return cormatin.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Fontenay range
   * @returns {Object3D[]}
   */
  fontenayParts() {
    return fontenay.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Fontenay range
   * @returns {Group}
   */
  fontenayClassiqueTrim() {
    return fontenay.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Fontenay range
   * @returns {Group}
   */
  fontenayModerneTrim() {
    return fontenay.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Rully range
   * @returns {Object3D[]}
   */
  rullyParts() {
    return rully.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Rully range
   * @returns {Group}
   */
  rullyClassiqueTrim() {
    return rully.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Rully range
   * @returns {Group}
   */
  rullyModerneTrim() {
    return rully.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Sully range
   * @returns {Object3D[]}
   */
  sullyParts() {
    return sully.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Sully range
   * @returns {Group}
   */
  sullyClassiqueTrim() {
    return sully.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Sully range
   * @returns {Group}
   */
  sullyModerneTrim() {
    return sully.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Sully 1800 (left) range
   * @returns {Object3D[]}
   */
  sully1800LeftParts() {
    return sully1800left.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Sully 1800 (left) range
   * @returns {Group}
   */
  sully1800LeftClassiqueTrim() {
    return sully1800left.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Sully 1800 (left) range
   * @returns {Group}
   */
  sully1800LeftModerneTrim() {
    return sully1800left.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Sully 1800 (right) range
   * @returns {Object3D[]}
   */
  sully1800RightParts() {
    return sully1800right.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Sully 1800 (right) range
   * @returns {Group}
   */
  sully1800RightClassiqueTrim() {
    return sully1800right.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Sully 1800 (right) range
   * @returns {Group}
   */
  sully1800RightModerneTrim() {
    return sully1800right.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Sully 2200 range
   * @returns {Object3D[]}
   */
  sully2200Parts() {
    return sully2200.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Sully 2200 range
   * @returns {Group}
   */
  sully2200ClassiqueTrim() {
    return sully2200.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Sully 2200 range
   * @returns {Group}
   */
  sully2200ModerneTrim() {
    return sully2200.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Volnay range
   * @returns {Object3D[]}
   */
  volnayParts() {
    return volnay.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Volnay range
   * @returns {Group}
   */
  volnayClassiqueTrim() {
    return volnay.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Volnay range
   * @returns {Group}
   */
  volnayModerneTrim() {
    return volnay.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Vougeot range
   * @returns {Object3D[]}
   */
  vougeotParts() {
    return vougeot.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Vougeot range
   * @returns {Group}
   */
  vougeotClassiqueTrim() {
    return vougeot.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Vougeot range
   * @returns {Group}
   */
  vougeotModerneTrim() {
    return vougeot.moderneTrim(this.#parts);
  }

  /** @param {Materials} materials */
  #applyMaterials(materials) {
    materials.applyStainlessSteelMaterial(
      this.#parts.getObjectByName(MeshName.cupboardVentCover),
      this.#parts.getObjectByName(MeshName.ovenVentCover),
      this.#parts.getObjectByName(MeshName.classiqueCupboardDoorBar),
      this.#parts.getObjectByName(MeshName.classiqueClunyOvenDoorBar),
      this.#parts.getObjectByName(MeshName.classiqueSullyOvenDoorBar),
      this.#parts.getObjectByName(MeshName.classiqueVolnayOvenDoorBar),
      this.#parts.getObjectByName(MeshName.moderneCupboardDoorBar),
      this.#parts.getObjectByName(MeshName.moderneVolnayCupboardDoorBar),
      this.#parts.getObjectByName(MeshName.moderneClunyOvenDoorBar),
      this.#parts.getObjectByName(MeshName.moderneSullyOvenDoorBar),
      this.#parts.getObjectByName(MeshName.moderneVolnayOvenDoorBar),
      this.#parts.getObjectByName(MeshName.moderneDoorBarSupport)
    );

    materials.applyGalvanizedSteelMaterial(
      this.#parts.getObjectByName(MeshName.moderneClunyDrawerBottomLip),
      this.#parts.getObjectByName(MeshName.moderneSullyDrawerBottomLip),
      this.#parts.getObjectByName(MeshName.moderneVolnayDrawerBottomLip)
    );
  }

  #setupShadows() {
    /** Shadow casters */
    // Bar supports
    this.#parts.getObjectByName(MeshName.towelBarleftSupport).castShadow = true;
    this.#parts.getObjectByName(
      MeshName.towelBarMidLeftSupport
    ).castShadow = true;
    this.#parts.getObjectByName(
      MeshName.towelBarCenterSupport
    ).castShadow = true;
    this.#parts.getObjectByName(
      MeshName.towelBarMidRightSupport
    ).castShadow = true;
    this.#parts.getObjectByName(
      MeshName.towelBarRightSupport
    ).castShadow = true;
    this.#parts.getObjectByName(MeshName.doorBarLeftSupport).castShadow = true;
    this.#parts.getObjectByName(MeshName.doorBarRightSupport).castShadow = true;

    // Bars
    this.#parts.getObjectByName(
      MeshName.classiqueSullyOvenDoorBar
    ).castShadow = true;
    this.#parts.getObjectByName(
      MeshName.moderneSullyOvenDoorBar
    ).castShadow = true;
    this.#parts.getObjectByName(
      MeshName.classiqueClunyOvenDoorBar
    ).castShadow = true;
    this.#parts.getObjectByName(
      MeshName.moderneClunyOvenDoorBar
    ).castShadow = true;
    this.#parts.getObjectByName(
      MeshName.classiqueVolnayOvenDoorBar
    ).castShadow = true;
    this.#parts.getObjectByName(
      MeshName.moderneVolnayOvenDoorBar
    ).castShadow = true;
    this.#parts.getObjectByName(
      MeshName.classiqueCupboardDoorBar
    ).castShadow = true;
    this.#parts.getObjectByName(
      MeshName.moderneCupboardDoorBar
    ).castShadow = true;

    // Vent covers
    this.#parts.getObjectByName(MeshName.ovenVentCover).castShadow = true;
    this.#parts.getObjectByName(MeshName.cupboardVentCover).castShadow = true;

    // Miscellaneous
    this.#parts.getObjectByName(MeshName.drawerPull).castShadow = true;
    this.#parts.getObjectByName(MeshName.sullyOvenDoor).castShadow = true;

    /** Shadow receivers */
    this.#parts.getObjectByName(MeshName.sullyOvenDoor).receiveShadow = true;
    this.#parts.getObjectByName(MeshName.clunyOvenDoor).receiveShadow = true;
    this.#parts.getObjectByName(
      MeshName.sullyStorageDrawer
    ).receiveShadow = true;
    this.#parts.getObjectByName(
      MeshName.clunyStorageDrawer
    ).receiveShadow = true;
    this.#parts.getObjectByName(MeshName.cupboardDoor).receiveShadow = true;
    this.#parts.getObjectByName(MeshName.volnayOvenDoor).receiveShadow = true;
    this.#parts.getObjectByName(
      MeshName.volnayCupboardDoor
    ).receiveShadow = true;
    this.#parts.getObjectByName(
      MeshName.volnayStorageDrawer
    ).receiveShadow = true;
  }
}

export { RangeParts };
