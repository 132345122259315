import { Group, Object3D } from 'three';

import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Cormatin range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Oven Door
  const ovenDoor = meshes.getObjectByName(MeshName.sullyOvenDoor).clone();
  ovenDoor.name = MeshName.ovenDoor;
  ovenDoor.position.x = 0;

  // Storage Drawer
  const storageDrawer = meshes
    .getObjectByName(MeshName.sullyStorageDrawer)
    .clone();
  storageDrawer.name = MeshName.storageDrawer;
  storageDrawer.position.x = 0;

  // Oven Vent Cover
  const ovenVentCover = meshes.getObjectByName(MeshName.ovenVentCover).clone();
  ovenVentCover.position.x = 0;

  return [ovenDoor, storageDrawer, ovenVentCover];
}

/**
 * All the classique trim parts for a Cormatin range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.position.x = -0.31;
  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.position.x = 0.31;

  // Oven Door Bar Supports
  const ovenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  ovenDoorBarSupportLeft.name = MeshName.ovenDoorBarLeftSupport;
  ovenDoorBarSupportLeft.position.x = -0.31;
  const ovenDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  ovenDoorBarSupportRight.name = MeshName.ovenDoorBarRightSupport;
  ovenDoorBarSupportRight.position.x = 0.31;

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.classiqueSullyOvenDoorBar)
    .clone();
  ovenDoorBar.name = MeshName.ovenDoorBar;
  ovenDoorBar.position.x = 0;

  // Drawer Pulls
  const drawerPullLeft = meshes.getObjectByName(MeshName.drawerPull).clone();
  drawerPullLeft.name = MeshName.drawerLeftPull;
  drawerPullLeft.position.x = -0.235;
  const drawerPullRight = drawerPullLeft.clone();
  drawerPullRight.name = MeshName.drawerRightPull;
  drawerPullRight.position.x = 0.235;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportRight,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    ovenDoorBar,
    drawerPullLeft,
    drawerPullRight
  );
}

/**
 * All the classique trim parts for a Cormatin range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.moderneSullyOvenDoorBar)
    .clone();
  ovenDoorBar.position.x = 0;

  // Oven Bar Supports
  const ovenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  ovenDoorBarSupportLeft.position.x = -0.32;
  const ovenDoorBarSupportRight = ovenDoorBarSupportLeft.clone();
  ovenDoorBarSupportRight.position.x = 0.32;

  // Storage Drawer Lip
  const drawerLip = meshes
    .getObjectByName(MeshName.moderneSullyDrawerBottomLip)
    .clone();
  drawerLip.position.x = 0;

  return trim.add(
    ovenDoorBar,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    drawerLip
  );
}
