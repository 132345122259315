import {
  ACESFilmicToneMapping,
  PCFShadowMap,
  PCFSoftShadowMap,
  WebGLRenderer,
} from 'three';

export function createRenderer() {
  const renderer = new WebGLRenderer({
    antialias: true,
    preserveDrawingBuffer: true, // Required to save images
  });
  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1;
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = PCFSoftShadowMap;

  return renderer;
}
