import { Group, Object3D } from 'three';
import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Chambertin range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Oven Door
  const ovenDoor = meshes.getObjectByName(MeshName.sullyOvenDoor).clone();
  ovenDoor.name = MeshName.ovenDoor;
  ovenDoor.position.x = -0.2;

  // Cupboard Door
  const cupboardDoor = meshes.getObjectByName(MeshName.cupboardDoor).clone();
  cupboardDoor.position.x = 0.352;

  // Storage Drawer
  const storageDrawer = meshes
    .getObjectByName(MeshName.sullyStorageDrawer)
    .clone();
  storageDrawer.name = MeshName.storageDrawer;
  storageDrawer.position.x = -0.2;

  // Oven Vent Cover
  const ovenVentCover = meshes.getObjectByName(MeshName.ovenVentCover).clone();
  ovenVentCover.position.x = -0.2;

  // Cupboard Vent Cover
  const cupboardVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCover)
    .clone();
  cupboardVentCover.position.x = 0.35;

  return [
    ovenDoor,
    cupboardDoor,
    storageDrawer,
    ovenVentCover,
    cupboardVentCover,
  ];
}

/**
 * All the classique trim parts for a Chambertin range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName('Towel_Bar_Support_Left')
    .clone();
  towelBarSupportLeft.position.x = -0.515;
  const towelBarSupportRight = meshes
    .getObjectByName('Towel_Bar_Support_Right')
    .clone();
  towelBarSupportRight.position.x = 0.515;

  // Oven Door Bar Supports
  const ovenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  ovenDoorBarSupportLeft.name = MeshName.ovenDoorBarLeftSupport;
  ovenDoorBarSupportLeft.position.x = -0.507;
  const ovenDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  ovenDoorBarSupportRight.name = MeshName.ovenDoorBarRightSupport;
  ovenDoorBarSupportRight.position.x = 0.11;

  // Cupboard Door Bar Supports
  const cupboardDoorBarSupportLeft = ovenDoorBarSupportLeft.clone();
  cupboardDoorBarSupportLeft.name = MeshName.cupboardBarLeftSupport;
  cupboardDoorBarSupportLeft.position.x = 0.23;
  const cupboardDoorBarSupportRight = ovenDoorBarSupportRight.clone();
  cupboardDoorBarSupportRight.name = MeshName.cupboardBarRightSupport;
  cupboardDoorBarSupportRight.position.x = 0.454;

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.classiqueSullyOvenDoorBar)
    .clone();
  ovenDoorBar.position.x = -0.2;
  ovenDoorBar.name = MeshName.ovenDoorBar;

  // Cupboard Door Bar
  const cupboardDoorBar = meshes
    .getObjectByName(MeshName.classiqueCupboardDoorBar)
    .clone();
  cupboardDoorBar.name = MeshName.cupboardDoorBar;
  cupboardDoorBar.position.x = 0.34;

  // Drawer Pulls
  const drawerPullLeft = meshes.getObjectByName(MeshName.drawerPull).clone();
  drawerPullLeft.name = MeshName.drawerLeftPull;
  drawerPullLeft.position.x = -0.42;
  const drawerPullRight = drawerPullLeft.clone();
  drawerPullRight.name = MeshName.drawerRightPull;
  drawerPullRight.position.x = 0.01;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportRight,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    cupboardDoorBarSupportLeft,
    cupboardDoorBarSupportRight,
    ovenDoorBar,
    cupboardDoorBar,
    drawerPullLeft,
    drawerPullRight
  );
}

/**
 * All the moderne trim parts for a Chambertin range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.moderneSullyOvenDoorBar)
    .clone();
  ovenDoorBar.position.x = -0.2;

  // Cupboard Door Bar
  const cupboardDoorBar = meshes
    .getObjectByName(MeshName.moderneCupboardDoorBar)
    .clone();
  cupboardDoorBar.position.x = 0.35;

  // Oven Bar Supports
  const ovenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  ovenDoorBarSupportLeft.position.x = -0.51;
  const ovenDoorBarSupportRight = ovenDoorBarSupportLeft.clone();
  ovenDoorBarSupportRight.position.x = 0.11;

  // Cupboard Bar Supports
  const cupboardDoorBarSupportLeft = ovenDoorBarSupportRight.clone();
  cupboardDoorBarSupportLeft.position.x = 0.225;
  const cupboardDoorBarSupportRight = cupboardDoorBarSupportLeft.clone();
  cupboardDoorBarSupportRight.position.x = 0.46;

  // Storage Drawer Lips
  const drawerLip = meshes
    .getObjectByName(MeshName.moderneSullyDrawerBottomLip)
    .clone();
  drawerLip.position.x = -0.2;

  return trim.add(
    ovenDoorBar,
    cupboardDoorBar,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    cupboardDoorBarSupportLeft,
    cupboardDoorBarSupportRight,
    drawerLip
  );
}
