import { Group, Object3D } from 'three';
import * as common from './common.js';
import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Cluny range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  return [...common.clunyOvenDoorsAndDrawers(meshes)];
}

/**
 * All the classique trim parts for a Cluny range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.position.x = -0.465;
  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.position.x = 0.465;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportRight,
    ...common.clunyOvenClassiqueTrim(meshes)
  );
}

/**
 * All the moderne trim parts for a Volnay range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  return trim.add(...common.clunyOvenModerneTrim(meshes));
}
