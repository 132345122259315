import { AmbientLight, DirectionalLight, HemisphereLight } from 'three';

export function createLights() {
  const ambientLight = new AmbientLight('white', 1);

  const hemisphereLight = new HemisphereLight('white', 'white', 1);

  const leftLight = new DirectionalLight('white', 1);
  leftLight.position.set(-3, 3, 4);
  leftLight.castShadow = true;
  leftLight.shadow.mapSize.width = 1024;
  leftLight.shadow.mapSize.height = 1024;
  leftLight.shadow.camera.top = 2;
  leftLight.shadow.camera.right = 2;
  leftLight.shadow.camera.bottom = -2;
  leftLight.shadow.camera.left = -2;
  leftLight.shadow.camera.near = 3;
  leftLight.shadow.camera.far = 7;

  const rightLight = new DirectionalLight('white', 1);
  rightLight.position.set(3, 3, 4);
  rightLight.castShadow = true;
  rightLight.shadow.mapSize.width = 1024;
  rightLight.shadow.mapSize.height = 1024;
  rightLight.shadow.camera.top = 2;
  rightLight.shadow.camera.right = 2;
  rightLight.shadow.camera.bottom = -2;
  rightLight.shadow.camera.left = -2;
  rightLight.shadow.camera.near = 3;
  rightLight.shadow.camera.far = 7;

  return { ambientLight, hemisphereLight, leftLight, rightLight };
}
